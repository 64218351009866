import { useEffect, useState } from 'react';
//import WorkIcon from '@mui/icons-material/Work';
//import StarsIcon from '@mui/icons-material/Stars';
//import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
//import HelpIcon from '@mui/icons-material/Help';
import paymentMethods from '../../../assets/images/payment-methods.svg';
import { useLocation } from 'react-router-dom';
import KMCraftLogo from '../../../assets/KM_logo.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const footerLinks = [
  {
    title: "Über uns",
    links: [
      {
        name: "Kontakt",
        redirect: "/ContactUs",
      },
      {
        name: "Über uns",
        redirect: "/about",
      },
    ]
  },
 
  {
    title: "Richtlinien",
    links: [
      {
        name: "Rückgaberecht",
        redirect: "/returnpolicy",
      },
      {
        name: "Nutzungsbedingungen",
        redirect: "/terms",
      },
      {
        name: "Sicherheit",
        redirect: "/securitypolicy",
      },
      {
        name: "Datenschutz",
        redirect: "/privacy",
      },
      {
        name: "Impressum ",
        redirect: "/impressum ",
      },
    
    ]
  },
  {
    title: "Soziale Medien",
    links: [
      {
        name: "Facebook",
        redirect: "https://www.facebook.com/Holzwurm.Spielwaren/",
        icon: <FacebookIcon />
      },
      {
        name: "Instagram",
        redirect: "https://www.instagram.com/holzwurm_aschaffenburg/",
        icon: <InstagramIcon />
      },
  
    ]
  }
]


const Footer = () => {

  const location = useLocation();
  const [adminRoute, setAdminRoute] = useState(false);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    setAdminRoute(location.pathname.split("/", 2).includes("admin"))
  }, [location]);

  return (
    <>
    {!adminRoute && (
      <>
        <footer className="mt-20 w-full py-4 sm:py-8 px-4 sm:px-10 bg-primary-white2 text-black text-sm sm:text-base border-b border-gray-600 flex flex-col sm:flex-row justify-between overflow-hidden">
          {/* Link-Bereich */}
          <div className="w-full sm:w-3/5 flex flex-col sm:flex-row justify-evenly">
            {footerLinks.map((section, index) => (
              <div className="flex flex-col gap-2 my-3 sm:my-6" key={index}>
                <h2 className="text-primary-grey mb-2 uppercase">{section.title}</h2>
                {section.links.map((link, linkIndex) => (
  <a href={link.redirect} className="hover:underline flex items-center" key={linkIndex}>
    {link.icon}
    <span className="ml-2">{link.name}</span>
  </a>
))}

              </div>
            ))}
          </div>

          {/* Trennlinie */}
          <div className="border-gray-600 h-36 w-1 border-l mr-5 mt-6 hidden sm:block"></div>

          {/* Kontaktdaten */}
          <div className="w-full sm:w-2/5 my-6 mx-5 sm:mx-0 flex flex-col justify-between">
            {/* Registrierte Adresse */}
            <div>
              <h2 className="text-primary-grey">Kontaktiere uns:</h2>
            
                <p className="mt-2 leading-6">
                  Mo - Fr: 09.30 - 18.00 Uhr   <br />
                 Samstag: 09.30 - 16.00 Uhr   </p>
         
                 <p className="mt-2 leading-6">

  <a href="https://www.google.com/maps/search/?api=1&query=Ro%C3%9Fmarkt+37%2C+63739+Aschaffenburg" target="_blank" rel="noopener noreferrer">
  Holzwurm Spielwaren UG<br />
    Roßmarkt 37<br />
    63739 Aschaffenburg<br />
  </a>
                <a className="text-primary-black" href="https://wa.me/49602125635">
               WhatsApp 
            
               & Telefon:     </a><a className="text-primary-blue" href="tel:+49602125635">+49 6021 25635</a>
             
                <br />                E-Mail: <a className="text-primary-blue" href="mailto:info@holzwurm-ab.de">info@holzwurm-ab.de</a>
                
              </p>
            </div>
          </div>
        </footer>

        {/* Zusätzlicher unterer Balken mit Urheberrecht, KM Code Craft Hinweis, Logo als Link und dunklerem Hintergrund */}
        <div className="px-1 py-3 w-full bg-dark-gray flex flex-col sm:flex-row justify-between items-center text-xs sm:text-sm text-white">
  <div className="flex items-center mb-2 sm:mb-0">
    <a href="https://code-craft.tech" target="_blank" rel="noopener noreferrer">
    
      <img src={KMCraftLogo} alt="KM Code Craft Logo" className="h-5 sm:h-6 md:h-8 mr-1 sm:mr-2" />
    </a>
    <span>&copy; {currentYear} Diese Webseite wurde von <a href="https://www.linkedin.com/in/anas-karah/" target="_blank" rel="noopener noreferrer" className="text-primary-blue">Anas Karah</a> bei KM Code Craft erstellt.</span>
  </div>
  <div className="flex items-center">
    <img draggable="false" className="h-5 sm:h-6 md:h-8 mr-1 sm:mr-2" src={paymentMethods} alt="Zahlungsmethoden" />
    <span className="hidden sm:block text-xs sm:text-sm md:text-base mr-1 sm:mr-2">abgewickelt durch</span>
    <img className="h-5 sm:h-6 md:h-8" src="https://www.paypalobjects.com/webstatic/i/logo/rebrand/ppcom.svg" alt="PayPal Logo" />
  </div>
</div>

      </>
    )}
  </>
);
}; 

export default Footer;
