import { useEffect } from 'react';
import Sidebar from './Sidebar';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Layouts/Loader';
import MinCategory from '../Layouts/MinCategory';
import MetaData from '../Layouts/MetaData';

const Account = () => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    const getLastName = () => {
        const nameArray = user.name.split(" ");
        return nameArray[nameArray.length - 1];
    }

    return (
        <>
            <MetaData title="My Profile" />

            {loading ? <Loader /> :
                <>
                    <MinCategory />
                    <main className="w-full mt-12 sm:mt-0 pt-30">

                        {/* <!-- row --> */}
                        <div className="flex gap-3.5 sm:w-11/12 sm:mt-4 m-auto mb-7">

                            <Sidebar activeTab={"profile"} />

                            {/* <!-- details column --> */}
                            <div className="flex-1 overflow-hidden shadow bg-white">
                                {/* <!-- edit info container --> */}
                                <div className="flex flex-col gap-12 m-4 sm:mx-8 sm:my-6">
                                    {/* <!-- personal info --> */}
                                    <div className="flex flex-col gap-5 items-start">
                                        <span className="font-medium text-lg">Persönliche Angaben <Link to="/account/update" className="text-sm text-primary-blue font-medium ml-8 cursor-pointer">Bearbeiten</Link></span>

                                        <div className="flex flex-col sm:flex-row items-center gap-3" id="personalInputs">
                                            <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border inputs cursor-not-allowed bg-gray-100 focus-within:border-primary-blue">
                                                <label className="text-xs text-gray-500">Vorname</label>
                                                <input type="text" value={user.name.split(" ", 1)} className="text-sm outline-none border-none cursor-not-allowed text-gray-500" disabled />
                                            </div>
                                            <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border inputs cursor-not-allowed bg-gray-100 focus-within:border-primary-blue">
                                                <label className="text-xs text-gray-500">Familienname, Nachname</label>
                                                <input type="text" value={getLastName()} className="text-sm outline-none border-none cursor-not-allowed text-gray-500" disabled />
                                            </div>
                                        </div>

                                        {/* <!-- gender 
                                         <div className="flex flex-col gap-2">
                                            <h2 className="text-sm">Dein Geschlecht</h2>
                                            <div className="flex items-center gap-8" id="radioInput">
                                                <div className="flex items-center gap-4 inputs text-gray-500 cursor-not-allowed">
                                                    <input type="radio" name="gender" checked={user.gender === "male"} id="male" className="h-4 w-4 cursor-not-allowed" disabled />
                                                    <label htmlFor="male" className="cursor-not-allowed">Male</label>
                                                </div>
                                                <div className="flex items-center gap-4 inputs text-gray-500 cursor-not-allowed">
                                                    <input type="radio" name="gender" checked={user.gender === "female"} id="female" className="h-4 w-4 cursor-not-allowed" disabled />
                                                    <label htmlFor="female" className="cursor-not-allowed">Female</label>
                                                </div>
                                            </div>
                                        </div>--> */}
                                       
                                        {/* <!-- gender --> */}

                                    </div>
                                    {/* <!-- personal info --> */}

                                    {/* <!-- email address info --> */}
                                    <div className="flex flex-col gap-5 items-start">
                                        <span className="font-medium text-lg">Email Address
                                            <Link to="/account/update" className="text-sm text-primary-blue font-medium ml-3 sm:ml-8 cursor-pointer">Bearbeiten</Link>
                                            <Link to="/password/update" className="text-sm text-primary-blue font-medium ml-3 sm:ml-8">Kennwort ändern</Link>
                                        </span>

                                        <div className="flex items-center gap-3">
                                            <div className="flex flex-col gap-0.5 sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100 cursor-not-allowed focus-within:border-primary-blue">
                                                <label className="text-xs text-gray-500">Email Address</label>
                                                <input type="email" value={user.email} className="text-sm outline-none border-none cursor-not-allowed text-gray-500" disabled />
                                            </div>
                                        </div>

                                    </div>
                                    {/* <!-- email address info --> */}

                                    {/* <!-- Handynummer info --> */}
                                    <div className="flex flex-col gap-5 items-start">
                                        <span className="font-medium text-lg">Handynummer
                                        <Link to="/account/update" className="text-sm text-primary-blue font-medium ml-8 cursor-pointer">Bearbeiten</Link></span>
                                      

                                        <div className="flex items-center gap-3">
                                            <div className="flex flex-col gap-0.5 sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100 cursor-not-allowed focus-within:border-primary-blue">
                                                <label className="text-xs text-gray-500">Handynummer</label>
                                                <input type="tel" value="+919876543210" className="text-sm outline-none border-none text-gray-500 cursor-not-allowed" disabled />
                                            </div>
                                        </div>

                                    </div>
                                    {/* <!-- Handynummer info --> */}

                                    {/* <!-- faqs --> */}
                                    <div className="flex flex-col gap-4 mt-4">
                                        <span className="font-medium text-lg mb-2">HÄUFIG GESTELLTE FRAGEN</span>

                                        <h4 className="text-sm font-medium">Was passiert, wenn ich meine E-Mail-Adresse (oder Handynummer) aktualisiere?</h4>
                                        <p className="text-sm">Ihre Anmelde-E-Mail-Adresse (oder Handynummer) ändert sich entsprechend. Sie werden alle kontobezogenen Mitteilungen an Ihre aktualisierte E-Mail-Adresse (oder Handynummer) erhalten.</p>

                                        <h4 className="text-sm font-medium">Wann wird mein Holzwurm-Konto auf die neue E-Mail-Adresse (oder Handynummer) aktualisiert?</h4>
                                        <p className="text-sm">Die Aktualisierung erfolgt sofort, nachdem Sie den an Ihre E-Mail (oder Handy) gesendeten Bestätigungscode verifiziert und die Änderungen gespeichert haben.</p>

                                        <h4 className="text-sm font-medium">Was passiert mit meinem bestehenden Holzwurm-Konto, wenn ich meine E-Mail-Adresse (oder Handynummer) aktualisiere?</h4>
                                        <p className="text-sm">Das Aktualisieren Ihrer E-Mail-Adresse (oder Handynummer) hat keine Auswirkungen auf die Gültigkeit Ihres Kontos. Ihr Konto bleibt voll funktionsfähig. Sie werden weiterhin Ihre Bestellhistorie, gespeicherte Informationen und persönlichen Daten sehen.</p>

                                        <h4 className="text-sm font-medium">Wird mein Verkäuferkonto beeinflusst, wenn ich meine E-Mail-Adresse aktualisiere?</h4>
                                        <p className="text-sm">Holzwurm hat eine 'Single Sign-On' Politik. Jegliche Änderungen werden ebenfalls in Ihrem Verkäuferkonto angezeigt.</p>

                                    </div>

                                    {/* <!-- faqs --> */}

                                    {/* <!-- deactivate account --> */}
                                    <Link className="text-sm text-primary-blue font-medium bg-red" to="/">Benutzerkonto deaktivieren</Link>
                                    {/* <!-- deactivate account --> */}
                                </div>
                                {/* <!-- edit info container --> */}

                                <img draggable="false" className="w-full object-contain" src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/myProfileFooter_4e9fe2.png" alt="footer" />
                            </div>
                            {/* <!-- details column --> */}
                        </div>
                    </main>
                </>
            }
        </>
    );
};

export default Account;
