import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';

const CouponManager = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [coupons, setCoupons] = useState([]);
    const [editingCoupon, setEditingCoupon] = useState({ code: '', discount: '', expireAt: '' });

    useEffect(() => {
        fetchCoupons();
    }, []);

    const fetchCoupons = async () => {
        try {
            const response = await axios.get('/api/v1/admin/coupons');
            setCoupons(response.data.coupons);
        } catch (error) {
            enqueueSnackbar("Fehler beim Laden der Coupons.", { variant: "error" });
        }
    };

    const handleDelete = async (code) => {
        try {
            await axios.delete(`/api/v1/admin/coupons/${code}`);
            enqueueSnackbar("Coupon gelöscht!", { variant: "success" });
            fetchCoupons(); // Refresh the list after deleting
        } catch (error) {
            enqueueSnackbar("Fehler beim Löschen des Coupons.", { variant: "error" });
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`/api/v1/admin/coupons/${editingCoupon.code}`, editingCoupon);
            enqueueSnackbar("Coupon aktualisiert!", { variant: "success" });
            setEditingCoupon({ code: '', discount: '', expireAt: '' });
            fetchCoupons(); // Refresh the list after updating
        } catch (error) {
            enqueueSnackbar("Fehler beim Aktualisieren des Coupons.", { variant: "error" });
        }
    };

    const handleEditChange = (e) => {
        setEditingCoupon({ ...editingCoupon, [e.target.name]: e.target.value });
    };

    return (
        
        <div>
<h1 className="text-lg font-medium uppercase">Coupons</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Rabatt</TableCell>
                            <TableCell>Gültigkeit</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {coupons.map((coupon) => (
                            <TableRow key={coupon.code}>
                                <TableCell>{coupon.code}</TableCell>
                                <TableCell>{coupon.discount}%</TableCell>
                                <TableCell>{new Date(coupon.expireAt).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    <Button onClick={() => setEditingCoupon(coupon)}>Bearbeiten</Button>
                                    <Button onClick={() => handleDelete(coupon.code)}>Löschen</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {editingCoupon.code && (
                <Paper style={{ margin: '20px', padding: '20px' }}>
                    <form onSubmit={handleUpdate}>
                        <TextField
                            label="Coupon Code"
                            variant="outlined"
                            fullWidth
                            required
                            name="code"
                            value={editingCoupon.code}
                            onChange={handleEditChange}
                            margin="normal"
                        />
                        <TextField
                            label="Rabatt (%)"
                            type="number"
                            variant="outlined"
                            fullWidth
                            required
                            name="discount"
                            value={editingCoupon.discount}
                            onChange={handleEditChange}
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            margin="normal"
                        />
                        <TextField
                            label="Gültigkeit (Datum)"
                            type="date"
                            variant="outlined"
                            fullWidth
                            required
                            name="expireAt"
                            value={editingCoupon.expireAt}
                            onChange={handleEditChange}
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                        />
                        <Button type="submit" color="primary" variant="contained" fullWidth>
                            Coupon aktualisieren
                        </Button>
                    </form>
                </Paper>
            )}
        </div>
    );
};

export default CouponManager;
