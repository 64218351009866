import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { clearErrors, deleteOrder, getAllOrders } from '../../actions/orderAction';
import { DELETE_ORDER_RESET } from '../../constants/orderConstants';
import Actions from './Actions';
import { formatDate } from '../../utils/functions';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';

const OrderTable = () => {
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { orders, error } = useSelector((state) => state.allOrders);
    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.order);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_ORDER_RESET });
        }
        dispatch(getAllOrders());
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteOrderHandler = (id) => {
        dispatch(deleteOrder(id));
    }

    const columns = [
        {
            field: "orderId",
            headerName: "Bestellungnummer",
            minWidth: 200,
            flex: 1,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.status === "Delivered" ? (
                                <span className="text-sm bg-green-100 p-1 px-2 font-medium rounded-full text-green-800">{params.row.status}</span>
                            ) : params.row.status === "Shipped" ? (
                                <span className="text-sm bg-yellow-100 p-1 px-2 font-medium rounded-full text-yellow-800">{params.row.status}</span>
                            ) : (
                                <span className="text-sm bg-purple-100 p-1 px-2 font-medium rounded-full text-purple-800">{params.row.status}</span>
                            )
                        }
                    </>
                )
            },
        },
     /**   {
            field: "deliveredNo",
            headerName: "Lieferung Nr.",
            type: "text",
            minWidth: 200, // Increase this value for a larger minimum width
            flex: 0.3, // Adjusting the flex value to take more space relative to other columns
        }, */
        {
            field: "name",
            headerName: "Name",
            type: "text",
            minWidth: 200, // Increase this value for a larger minimum width
            flex: 0.3, // Adjusting the flex value to take more space relative to other columns
        },
        
        {
            field: "itemsQty",
            headerName: "Artikelmenge",
            type: "number",
            minWidth: 100,
            flex: 0.1,
        },
        {
            field: "amount",
            headerName: "Price",
            type: "number",
            minWidth: 200,
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <span>€{params.row.amount.toLocaleString()}</span>
                );
            },
        },
        {
            field: "orderOn",
            headerName: "Bestellen Sie am",
            type: "date",
            minWidth: 200,
            flex: 0.5,
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 100,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"order"} deleteHandler={deleteOrderHandler} id={params.row.id} />
                );
            },
        },
    ];

    const rows = [];

    orders && orders.forEach((order) => {
        rows.unshift({
            id: order._id,
            orderId:order.orderId,
            itemsQty: order.orderItems.length,
            deliveredNo: order.GeliefertNo,
           
            amount: order.totalPrice,
            orderOn: formatDate(order.createdAt),
            status: order.orderStatus,
        });
    });
    const sortedOrders = React.useMemo(() => {
        return (orders || []).slice().sort((a, b) => b.orderId - a.orderId);
    }, [orders]);
    const filteredRows = sortedOrders?.filter((order) => {
        return order.orderId.includes(search);
    }).map(order => ({
        id: order._id,
        orderId: order.orderId,
        itemsQty: order.orderItems.length,
        name:order.shippingInfo.name,
        deliveredNo: order.GeliefertNo,
        amount: order.totalPrice,
        orderOn: formatDate(order.createdAt),
        status: order.orderStatus,
    })).sort((a, b) => new Date(b.orderOn) - new Date(a.orderOn)) || [];
    return (
        <>
            <MetaData title="Admin Orders | Holzwurm" />
            {loading && <BackdropLoader />}

            <h1 className="text-lg font-medium uppercase">Bestellungen</h1>
            <input
                type="number"
                placeholder="Bestellungnummer suchen"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="mb-4 mt-2 p-2 border rounded"
            />
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 470 }}>
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={10}
                    disableSelectIconOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}
                />
            </div>
        </>
    );
};

export default OrderTable;