import { useSelector  } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import CartItem from './CartItem';
import EmptyCart from './EmptyCart';
import PriceSidebar from './PriceSidebar';
import SaveForLaterItem from './SaveForLaterItem';

const Cart = () => {
    const { isAuthenticated } = useSelector((state) => state.user);
    const navigate = useNavigate();
   
    const { cartItems } = useSelector((state) => state.cart);
    const { saveForLaterItems } = useSelector((state) => state.saveForLater);
 
    const placeOrderHandler = () => {
       
        navigate('/login?redirect=shipping');
    }
    const continueAsGuestHandler = () => {
        // Markieren des Benutzers als Gast im lokalen Speicher
   
        localStorage.setItem('isGuest', 'true');
        navigate('/shipping');
    };
    
    return (
        <>
            <MetaData title="Warenkorb | Holzwurm" />
            <main className="w-full mt-20 pt-10">
                <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
                    <div className="flex-1">
                        
                        <div className="flex flex-col shadow bg-white">
                            
                            <span className="font-medium text-lg px-2 sm:px-8 py-4 border-b">Einkaufswagen ({cartItems.length})</span>
                
                            {cartItems && cartItems.length === 0 && <EmptyCart />}

                            {cartItems && cartItems.map((item) => (
  <CartItem key={item.product} {...item} inCart={true} />
))}
                            <div className="flex flex-col sm:flex-row justify-between items-center px-2 sm:px-8 py-4">
                                {!isAuthenticated && (
                                    <button onClick={continueAsGuestHandler} className={`${cartItems.length < 1 ? "bg-primary-grey cursor-not-allowed" : "bg-primary-green"} w-full sm:w-auto px-6 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm`}>ALS GAST FORTFAHREN</button>
                                )}
                                <button onClick={placeOrderHandler} disabled={cartItems.length < 1} className={`${cartItems.length < 1 ? "bg-primary-grey cursor-not-allowed" : "bg-primary-orange"} w-full sm:w-auto px-6 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm`}>BESTELLUNG AUFGEBEN</button>
                            </div>
                        </div>

                        <div className="flex flex-col mt-5 shadow bg-white">
                            <span className="font-medium text-lg px-2 sm:px-8 py-4 border-b">Auf die Merkliste ({saveForLaterItems.length})</span>
                            {saveForLaterItems && saveForLaterItems.map((item) => (
  <SaveForLaterItem key={item.product} {...item} />
))}                        </div>
                    </div>

                    <PriceSidebar cartItems={cartItems} />
                </div>
            </main>
        </>
    );
};

export default Cart;