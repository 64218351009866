import { useEffect  } from 'react';
//import Categories from '../Layouts/Categories';
//import Categories from '../Layouts/Categories';
import Banner from './Banner/Banner';
//import DealSlider from './DealSlider/DealSlider';
import ProductSlider from './ProductSlider/ProductSlider';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, getSliderProducts } from '../../actions/productAction';
import { useSnackbar } from 'notistack';
import MetaData from '../Layouts/MetaData';
import logo from '../../assets/images/logo.png';
const Home = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { error, loading } = useSelector((state) => state.products);

  useEffect(() => {
    
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getSliderProducts());
  }, [dispatch, error, enqueueSnackbar]);
 
// <Categories />
  return (
    <>
      <MetaData title="Holzwurm" />

   

      <main className="flex flex-col gap-3 px-2 mt-16 sm:mt-2 pt-20 ">
      <div className="flex flex-col items-center gap-3 px-2 mt-16 ">

      <img draggable="false" className="h-44 w-66 object-contain -mt-4" src={logo} alt="Holzwurm Logo" />
</div>
        <Banner />
        {/*<!-- <Categories /> -->*/}
          {/*<DealSlider title={"Discounts for You"} /> -->*/}
         
          {!loading && <ProductSlider title={"Ihre persönlichen Empfehlungen"} tagline={"Ausgewählt nach Ihrem Geschmack"} />}
        {/*<!-- <DealSlider title={"Top Brands, Best Price"} />-->*/}
       
       
        {!loading && <ProductSlider title={"Entdecken Sie mehr"} tagline={"Passend zu Ihren Interessen"} />}
      
       {/*<!--   <DealSlider title={"Top Offers On"} /> -->*/}
      
        {!loading && <ProductSlider title={"Verpassen Sie diese nicht!"} tagline={"Inspiriert von Ihrer Bestellung"} />}
      </main>
     
    </>
  );
};

export default Home;
