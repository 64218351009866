import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Searchbar from './Searchbar';
import logo from '../../../assets/images/logo.png';
import PrimaryDropDownMenu from './PrimaryDropDownMenu';
import SecondaryDropDownMenu from './SecondaryDropDownMenu';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Categories from '../Categories';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
const Header = () => {
  const location = useLocation();
  const [, setIsScrolled] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.user);

  const { cartItems } = useSelector(state => state.cart);

  const [togglePrimaryDropDown, setTogglePrimaryDropDown] = useState(false);
  const [toggleSecondaryDropDown] = useState(false);
  useEffect(() => {
    const pathSegments = location.pathname.split("/").slice(1); // Entfernt den leeren String am Anfang
    const adminPaths = ["admin", "ContactUs", "returnpolicy", "about", "terms","securitypolicy","password","login","account"];
    
    // Prüft, ob mindestens ein Segment im Pfad einem Wert in adminPaths entspricht
    const isAdminRoute = pathSegments.some(segment => adminPaths.includes(segment));
    
    setAdminRoute(isAdminRoute);
  }, [location]);
  
  const [adminRoute, setAdminRoute] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Fügen Sie den Event-Listener hinzu, wenn die Komponente gemountet wird
    window.addEventListener('scroll', handleScroll);

    // Entfernen Sie den Event-Listener, wenn die Komponente unmounted wird
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (

<header className=" header bg-primary-white2 text-black fixed  top-0 py-2 z-10 w-full">
  <div className="w-full sm:w-9/12 px-1 h-1 m-auto flex flex-wrap sm:px-4 justify-between items-center relative ">

      {/* <!-- logo & search container --> */}
      <div className="flex items-center  flex-1 bg-primary-white2">
      <Link className="h-6 sm:h-7 mr-2 sm:mr-4" to="/">
          <img draggable="false" className="h-full w-full object-contain" src={logo} alt="Holzwurm-Logo" />
        </Link>
  
        <Searchbar />
      </div>
      {/* <!-- logo & search container --> */}
  
      {/* <!-- right navs --> */}
      <div className="flex items-center justify-between ml-1 sm:ml-0 gap-0.5 sm:gap-4 relative py-0.1 bg-primary-white2">
  
        {!isAuthenticated ?
          <Link to="/login" className="px-3 sm:px-9 py-0.5 text-primary-vanille bg-black border font-medium rounded-lg cursor-pointer ">Anmelden</Link>
          :
          (
            <span className="userDropDown flex items-center text-black font-medium gap-1 z-50 cursor-pointer  " onClick={() => setTogglePrimaryDropDown(!togglePrimaryDropDown)}>{user.name && user.name.split(" ", 1)}
              <span>{togglePrimaryDropDown ? <ExpandLessIcon sx={{ fontSize: "16px" }} /> : <ExpandMoreIcon sx={{ fontSize: "16px" }} />}</span>
            </span>
          )
        }
  
        {togglePrimaryDropDown && <PrimaryDropDownMenu setTogglePrimaryDropDown={setTogglePrimaryDropDown} user={user} />}
  
        {toggleSecondaryDropDown && <SecondaryDropDownMenu />}
  
        <Link to="/cart" className="flex items-center text-black font-medium gap-2   relative">
          <span><ShoppingCartIcon /></span>
          {cartItems.length > 0 &&
            <div className="w-1 h-1 p-2 bg-red-500 text-xs rounded-full absolute -top-2 left-3 flex justify-center items-center border">
              {cartItems.length}
            </div>
          }
          Warenkorb
        </Link>
      </div>
      {/* <!-- right navs --> */}
  
    </div>
    {/* <!-- navbar container --> */}
    {!adminRoute && (
    <div className=" categories-containersm:hidden md:hidden flex justify-end z-0 overflow-hidden  ">
    <Categories /></div>    
  )}
  </header>
  
  )
};

export default Header;
