import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import logo from '../../assets/images/logo.png';

// Styles definieren
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 30,
  },
  logo: {
    width: 100,
    margin: '0 auto 10px',
  },
  header: {
    fontSize: 20,
    marginBottom: 8,
    textAlign: 'center',
    color: '#003366',
  },
  metadataSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    alignItems: 'baseline'
  },
  leftColumn: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  rightColumn: {
    fontSize: 12,
    textAlign: 'right',
    lineHeight: 1.5,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    color: '#333',
    fontWeight: 'bold'
  },
  address: {
    fontSize: 12,
    marginBottom: 2,
  },
  itemContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    borderBottomStyle: 'solid',
    paddingVertical: 5,
  },
  itemDescription: {
    fontSize: 12,
    width: '60%',
  },
  itemQtyPrice: {
    fontSize: 12,
    width: '20%',
    textAlign: 'right',
  },
  itemHeader: {
    flexDirection: 'row',
    marginBottom: 5,
    paddingBottom: 5,
    borderBottomWidth: 2,
    borderBottomColor: '#000',
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 12,
    color: '#aaa',
  },
  totalLine: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: 5,
    borderTopWidth: 1,
    borderTopColor: '#000',
  }
});

// Dokumentkomponente
const MyDocument = ({ order }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={logo} />
      <Text style={styles.header}>Bestellungsübersicht</Text>
      <View style={styles.metadataSection}>
        <View style={styles.leftColumn}>
          <Text style={styles.address}>Holzwurm Spielwaren UG</Text>
          <Text style={styles.address}>Roßmarkt 37, 63739 Aschaffenburg</Text>
          <Text style={styles.address}>WhatsApp & Telefon: +49 6021 25635</Text>
          <Text style={styles.address}>E-Mail: info@holzwurm-ab.de</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text>Bestellnummer: {order.orderId}</Text>
          <Text>Bestelldatum: {new Date(order.createdAt).toLocaleDateString('de-DE')}</Text>
        </View>
      </View>
      <View style={styles.leftColumn}>
      <Text style={styles.title}>Lieferdetails</Text>
      <Text style={styles.address}>{order.shippingInfo.name}</Text>
      <Text style={styles.address}>{order.shippingInfo.address}</Text>
      <Text style={styles.address}>{order.shippingInfo.city}, {order.shippingInfo.state} - {order.shippingInfo.pincode}</Text>
      </View>
      <Text  style={styles.title}></Text>
      <Text></Text>
      <Text style={styles.title}>Bestellungsdetails</Text>
      <View style={styles.itemHeader}>
        <Text style={styles.itemDescription}>Artikel</Text>
        <Text style={styles.itemQtyPrice}>Menge</Text>
        <Text style={styles.itemQtyPrice}>Preis</Text>
      </View>
      {order.orderItems.map((item, index) => (
        <View key={index} style={styles.itemContainer}>
          <Text style={styles.itemDescription}>{item.name}</Text>
          <Text style={styles.itemQtyPrice}>{item.quantity}</Text>
          <Text style={styles.itemQtyPrice}>{item.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Text>
        </View>
      ))}

<View style={styles.totalLine}>
        <Text style={styles.itemQtyPrice}>Lieferkosten:</Text>
        <Text style={styles.itemQtyPrice}>6,95 €</Text>
      </View>
      <View style={styles.totalLine}>
        <Text style={styles.itemQtyPrice}>Gesamtsumme:</Text>
        <Text style={styles.itemQtyPrice}>{(order.totalPrice ).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Text>
      </View>
      <Text style={styles.footer}>Ihre Zahlung wurde bestätigt und die Bestellung wird bearbeitet. Vielen Dank für Ihren Einkauf bei Holzwurm Spielwaren!</Text>
    </Page>
  </Document>
);
// PDF-Download-Link Komponente
const OrderPDFDownload = ({ order }) => (
  <PDFDownloadLink document={<MyDocument order={order} />} fileName={`Bestellungsübersicht-${order.orderId}.pdf`}>
    {({ blob, url, loading, error }) =>
      loading ? 'Laden des Dokuments...' : 'Bestellungsübersicht als PDF herunterladen'
    }
  </PDFDownloadLink>
);

export default OrderPDFDownload;
