import Holidays from 'date-holidays';
export const getDiscount = (price, cuttedPrice) => {
  // Überprüfen, ob cuttedPrice 0, null oder undefined ist
  if (!cuttedPrice) {
      return "0"; // Direkt "0" als String zurückgeben
  }
  // Berechnung durchführen, wenn cuttedPrice gültig und nicht 0 ist
  return (((cuttedPrice - price) / cuttedPrice) * 100).toFixed();
}


export const getDeliveryDate = () => {
  const deliveryDate = new Date();
  deliveryDate.setDate(deliveryDate.getDate() + 3);
  
  const hd = new Holidays('DE', 'BY'); // 'DE' for Germany and 'BY' for Bavaria

  // Check if the calculated delivery date is a holiday, or Sunday (0), or Monday (1)
  while (deliveryDate.getDay() === 0 || deliveryDate.getDay() === 1 || hd.isHoliday(deliveryDate)) {
      deliveryDate.setDate(deliveryDate.getDate() + 1);
  }

  // Check if the new delivery date is a Friday, then add extra days as needed
  if (deliveryDate.getDay() === 5) {
      deliveryDate.setDate(deliveryDate.getDate() + 3);
  }

  return deliveryDate.toLocaleDateString('de-DE', {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  });
}
  
  export const formatDate = (dt) => {
    return new Date(dt).toLocaleDateString('de-DE', {
      //year: 'numeric', // "numerisch" für das Jahr
      month: 'long', // "lang" für den Monatsnamen
      day: 'numeric' // "numerisch" für den Tag
    });
  }
  

export const getRandomProducts = (prodsArray, n) => {
    return prodsArray.sort(() => 0.5 - Math.random()).slice(0, n)
}