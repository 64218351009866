import React, { useState } from 'react';
//import { useHistory } from 'react-router-dom'; // Für React Router v5
// import { useNavigate } from 'react-router-dom'; // Für React Router v6
import './ContactUs.css';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Verhindert das Neuladen der Seite
        try {
            const response = await fetch('/api/v1/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                // Nachricht erfolgreich gesendet
                enqueueSnackbar('Nachricht gesendet!');
                // Setzt den Formularzustand zurück
                setFormData({ name: '', email: '', message: '' });
                // Leitet den Benutzer zur Startseite weiter
                // Für React Router v5
                navigate('/'); // Für React Router v6
            } else {
                // Fehler beim Senden der Nachricht
                enqueueSnackbar('Fehler beim Senden der Nachricht');
            }
        } catch (error) {
            // Fehler im Catch-Block
            enqueueSnackbar(error, { variant: "Fehler" });
            alert('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        }
    };

    return (
        <div className="contact-container">
            <h2>Contact Us</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" required onChange={handleChange} value={formData.name} />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" required onChange={handleChange} value={formData.email} />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea id="message" rows="5" required onChange={handleChange} value={formData.message}></textarea>
                </div>
                <button type="submit" className="submit-button">Send Message</button>


            </form>
        </div>
    );
};

export default ContactUs;
