import React from 'react';
import './SecurityPolicy.css'; // Angenommen, Sie haben eine CSS-Datei für das Styling

const SecurityPolicy = () => {
    return (
        <div className="security-policy-container">
            <h1>Sicherheitsrichtlinien</h1>
            <p>Im Holzwurm Online-Shop nehmen wir die Sicherheit Ihrer persönlichen Daten und die Integrität der von Ihnen getätigten Zahlungen sehr ernst. Wir möchten, dass Sie beim Einkaufen bei uns ein sicheres Gefühl haben. Hier erfahren Sie, wie wir die Sicherheit gewährleisten und welche Zahlungsmethoden zur Verfügung stehen.</p>
            
            <h2>Zahlungssicherheit</h2>
            <p>Alle Zahlungsvorgänge in unserem Online-Shop sind mit modernster SSL-Verschlüsselungstechnologie gesichert. Dies stellt sicher, dass Ihre persönlichen Informationen, einschließlich Kreditkarteninformationen, sicher übertragen werden.</p>
            
            <h3>Unterstützte Zahlungsmethoden</h3>
            <p>Wir bieten eine Reihe von Zahlungsmethoden an, um den Kaufprozess so bequem wie möglich zu gestalten:</p>
            <ul>
                <li><strong>Visa</strong> und <strong>MasterCard</strong>: Zwei der weltweit führenden Kreditkartenanbieter, bekannt für ihre Sicherheit und Zuverlässigkeit.</li>
                <li><strong>Überweisung</strong>: Für diejenigen, die eine direkte Zahlung von ihrem Bankkonto bevorzugen.</li>
                <li><strong>PayPal</strong>: Eine weit verbreitete und sichere Methode für Online-Zahlungen, die zusätzlichen Schutz bietet.</li>
            </ul>
            
            <h2>Datenschutz</h2>
            <p>Wir respektieren Ihre Privatsphäre und sind verpflichtet, Ihre persönlichen Daten zu schützen. Ihre Informationen werden nur zur Bearbeitung Ihrer Bestellung verwendet und unter keinen Umständen an Dritte weitergegeben.</p>
            
            <h2>Kontakt</h2>
            <p>Für weitere Fragen zur Sicherheit oder unseren Zahlungsmethoden, zögern Sie bitte nicht, uns zu kontaktieren.</p>
        </div>
    );
};

export default SecurityPolicy;
