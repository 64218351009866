import React from 'react';
import'./Impressum.css';
import w from '../../../../assets/Muster_Widerrufsformular.pdf';
const Impressum = () => {
  return (
    <div className="impressum-container">
      <h1>Impressum</h1>
      <p><strong>Holzwurm Spielwaren UG (haftungsbeschränkt)</strong></p>
      <address>
        Roßmarkt 37<br />
        63739 Aschaffenburg, DE<br />
        Telefon: +49 6021 25635<br />
        E-Mail: <a href="mailto:info@holzwurm-ab.de">info@holzwurm-ab.de</a>
      </address>
      
      <p><strong>Geschäftsführerin:</strong> Jenny Alptekin</p>
      
      <p><strong>Registergericht:</strong> Amtsgericht Aschaffenburg<br />
      <strong>HRB:</strong> 17190</p>

      <p><strong>VERANTWORTLICH GEMÄSS §5 TELEMEDIENGESETZ</strong><br />
      Verantwortlich für den Inhalt: Jenny Alptekin<br />
      Verantwortlich für den Inhalt (Adresse): Roßmarkt 37, 63739 Aschaffenburg</p>
      
      <p><strong>Verantwortlich im Sinne des Presserechts:</strong> Jenny Alptekin</p>

      <p><strong>USt-IdNr.:</strong> DE362 201 508</p>

      <p>Sie erreichen unseren Kundendienst für Fragen, Reklamationen und Beanstandungen telefonisch unter +49 6021 25635 sowie per Email unter <a href="mailto:info@holzwurm-ab.de">info@holzwurm-ab.de</a>. Für Anfragen können Sie auch das Kontaktformular auf unserer Internetseite <a href="http://www.holzwurm-aschaffenburg.de">www.holzwurm-aschaffenburg.de</a> nutzen.</p>

      <h2>Disclaimer</h2>
      <p>Bei direkten oder indirekten Verweisen auf fremde Webseiten  ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind.</p>

      <h2>Urheber- und Kennzeichenrecht</h2>
      <p>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.</p>

      <h2>Streitschlichtung</h2>
      <p>Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a> aufrufen können. Im Übrigen sind wir zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.</p>

      <h2>Widerrufsrecht</h2>
      <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
      <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.</p>
      <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Holzwurm Spielwaren UG (haftungsbeschränkt), Roßmarkt 37, 63739 Aschaffenburg (Innenstadt), vertreten durch Jenny Alptekin, Tel.: 06021/25635, E-Mail: atalanda@holzwurm-ab.de), mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte <a href={w}>Muster_Widerrufsformular</a> verwenden, das jedoch nicht vorgeschrieben ist.</p>
      <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>

      <h2>Folgen des Widerrufs</h2>
      <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.</p>
      <p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben.</p>

      <h2>Versandkosten</h2>
      <p>Die Lieferkosten können je nach Menge, Distanz und Liefergeschwindigkeit variieren. Sie werden Ihnen im Bestellprozess eindeutig angezeigt.</p>

      <h2>Steuerinformationen</h2>
      <p>Die angegebenen Preise sind inklusive Mehrwertsteuer.</p>

      <h2>Datenschutz & Sicherheit</h2>
      <p>Durch Ihren Besuch auf unserer Webseite stimmen Sie den in der Datenschutzerklärung beschriebenen Vorgehensweisen zu.</p>

      <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>
      <p>Die Allgemeinen Geschäftsbedingungen (AGB) sind gültig.</p>




      <h2>Kontakt</h2>
      <p>Sie erreichen unseren Kundendienst für Fragen, Reklamationen und Beanstandungen telefonisch unter <a className="text-primary-blue" href="tel:+49602125635">+49 6021 25635</a> sowie per Email unter <a href="mailto:info@holzwurm-ab.de">info@holzwurm-ab.de</a>.</p>
  </div>
  );
}

export default Impressum;
