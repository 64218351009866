import React from 'react';
import {  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Paper, Typography, Button } from '@mui/material';
import MetaData from '../Layouts/MetaData';
import OrderPDFDownload from './OrderPDFDownload'; // Stellen Sie sicher, dass der Pfad korrekt ist
const AfterOrderSuccess = () => {
    const { state } = useLocation();
    const { order } = useSelector(state => state.newOrder);
   
    const navigate = useNavigate();
    if (!state ) {
        return <div>Bestellinformationen nicht verfügbar.</div>;
    }
 
    if ( !order) {
      navigate("/")
        return <div>Bestellinformationen nicht verfügbar.</div>;
    }

    return (
        <>
            <MetaData title="Vielen Dank für Ihren Einkauf! | Holzwurm" />
            <main className="w-full mt-14 sm:mt-4 pt-40">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Paper elevation={3} className="p-6">
                        <Typography variant="h4" gutterBottom>
                            Vielen Dank für Ihren Einkauf!
                        </Typography>
                        <Typography variant="subtitle1">
                            Wir haben eine Bestätigung Ihrer Bestellung an {order.paymentInfo.email} gesendet.
                        </Typography>
                        <Typography variant="h6" className="mt-4">
                            Bestellung Nummer: {order.orderId}
                        </Typography>
                        <Typography variant="h6" className="mt-4">
                            Lieferdetails
                        </Typography>
                        <Typography>
                            {order.shippingInfo.name}, {order.shippingInfo.address}, {order.shippingInfo.city}, {order.shippingInfo.state} - {order.shippingInfo.pincode}
                        </Typography>
                        {order.orderItems.map((item, index) => (
                            <div key={index} className="flex justify-between items-center mt-4 p-2 border-b">
                                <div className="flex items-center">
                                    <img src={item.image} alt={item.name} style={{ width: "50px", height: "50px", marginRight: "20px" }} />
                                    <div>
                                        <Typography variant="body1">{item.name}</Typography>
                                        <Typography variant="body2" color="textSecondary">Menge: {item.quantity}</Typography>
                                    </div>
                                </div>
                                <Typography variant="body1">
                                    {item.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                                </Typography>
                            </div>
                        ))}
                     
                     
                        
                    </Paper>
                    <Button variant="contained" color="primary" className="mt-4" >
                        <OrderPDFDownload order={order} />
                        </Button>
                </div>
            </main>
        </>
    );
};

export default AfterOrderSuccess;
