import React from 'react';
import './TermsOfUse.css'; // Angenommen, Sie haben spezifisches CSS für diese Seite.

const TermsOfUse = () => {
    return (
        <div className="terms-of-use-container">
            <h1>Nutzungsbedingungen</h1>
            <p>Willkommen bei Holzwurm! Diese Nutzungsbedingungen regeln Ihre Nutzung unserer Website und der darauf angebotenen Dienste.</p>
            
            <h2>1. Geltungsbereich</h2>
            <p>Diese Nutzungsbedingungen gelten für alle Besucher unserer Website und Kunden unseres Online-Shops.</p>
            
            <h2>2. Urheberrechte</h2>
            <p>Der gesamte Inhalt unserer Website, einschließlich Texte, Grafiken, Logos und Bilder, ist urheberrechtlich geschützt und bleibt unser Eigentum oder das Eigentum unserer Inhaltslieferanten.</p>
            
            <h2>3. Nutzung der Website</h2>
            <p>Sie verpflichten sich, unsere Website nur für rechtmäßige Zwecke zu nutzen und keine Handlungen vorzunehmen, die die Nutzung der Website für andere Benutzer beeinträchtigen könnten.</p>
            
            <h2>4. Haftungsausschluss</h2>
            <p>Wir übernehmen keine Haftung für Schäden, die durch die Nutzung unserer Website entstehen, soweit diese nicht auf Vorsatz oder grobe Fahrlässigkeit unsererseits zurückzuführen sind.</p>
            
            <h2>5. Änderung der Nutzungsbedingungen</h2>
            <p>Wir behalten uns das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Die jeweils aktuelle Version der Nutzungsbedingungen finden Sie auf unserer Website.</p>
            
            <h2>6. Kontakt</h2>
            <p>Bei Fragen zu diesen Nutzungsbedingungen wenden Sie sich bitte an uns über die auf unserer Website angegebenen Kontaktmöglichkeiten.</p>
        </div>
    );
};

export default TermsOfUse;
