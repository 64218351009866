import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PriceSidebar from './PriceSidebar';
import Stepper from './Stepper';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { newOrder } from '../../actions/orderAction';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { clearErrors } from '../../actions/orderAction';
import { useSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Radio from '@mui/material/Radio';
//import RadioGroup from '@mui/material/RadioGroup';
import MetaData from '../Layouts/MetaData';
import { emptyCart } from '../../actions/cartAction';

axios.defaults.withCredentials = true
const Payment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
     //const stripe = useStripe();
    // const elements = useElements();
     //const paymentBtn = useRef(null);
     const [, setPaymentSuccess] = useState(false);

    const { shippingInfo, cartItems } = useSelector((state) => state.cart);
    const { user } = useSelector((state) => state.user);
    const { error } = useSelector((state) => state.newOrder);
    const [guestEmail] = useState(localStorage.getItem('guestEmail') );
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [couponApplied, setCouponApplied] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const userEmail = user?.email || guestEmail;
    const itemsPrice =cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    // Berechnung der Versandkosten basierend auf dem Gesamtbetrag der Artikel
  //  let deliveryCharges = itemsPrice >= 50 ? 0 : 6.95;
    const[deliveryCharges, setDeliveryCharges] =useState(itemsPrice >= 50 ? 0 : 6.95)
    const [totalIncludingDelivery, setTotalIncludingDelivery] = useState(calculateInitialTotal());
    const [isReadyForPayment, setIsReadyForPayment] = useState(false);
//console.log(shippingInfo);
  const initialOptions = {
    clientId: "ASwn8tVg6LG84dRWYrMEtNEcwokJiVgsQczironoQ8PcVktQ3ulBgxRSNbWTukdnr10i_qKHvTLR3Tz1",
    currency: "EUR",
        intent: "capture",
     
    };
    function calculateInitialTotal() {
      const itemsTotal = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
      return itemsTotal + (itemsTotal >= 50 ? 0 : 6.95);
  }
    useEffect(() => {
      const newTotal1 = itemsPrice - (itemsPrice * (discount / 100)) ;
      const deliveryCharges1=newTotal1 >= 50 ? 0 : 6.95
      setDeliveryCharges(deliveryCharges1)
 
      const newTotal = itemsPrice - (itemsPrice * (discount / 100)) + deliveryCharges;
      setTotalIncludingDelivery(newTotal);
      setIsReadyForPayment(false);  // Disable PayPal buttons during recalculation
  }, [discount, itemsPrice, deliveryCharges]);

  // Enable PayPal buttons after total is updated
  useEffect(() => {
      if (totalIncludingDelivery !== undefined) {
          setIsReadyForPayment(true);
      }
  }, [totalIncludingDelivery]);
  const applyCoupon = async () => {
    if (!couponCode) {
        if (couponApplied) {
            setDiscount(0); // Setzen Sie den Rabatt zurück, wenn das Coupon-Feld geleert wird
            updateTotalPrice(0);

        }
        return;  // Frühzeitig beenden, wenn kein Coupon-Code eingegeben ist
    }

    try {
        const { data } = await axios.post('/api/v1/coupons/validate', { couponCode });
        setDiscount(data.discount);
        setCouponApplied(true);
        updateTotalPrice(data.discount)
       // console.log( totalIncludingDelivery);
        enqueueSnackbar(`Coupon angewendet! ${data.discount}% Rabatt gewährt.`, { variant: "success" });
    } catch (error) {
        setErrorMessage("Ungültiger Coupon oder Coupon nicht gefunden.");
        setDiscount(0);
        updateTotalPrice(0);  // Setzen Sie den Preis ohne Rabatt, wenn der Coupon ungültig ist
    }
};

const updateTotalPrice = (newDiscount) => {
    const newTotal1 = itemsPrice - (itemsPrice * (newDiscount / 100))
    const deliveryCharges1=newTotal1 >= 50 ? 0 : 6.95
    setDeliveryCharges(deliveryCharges1);
    
    const newTotal = itemsPrice - (itemsPrice * (newDiscount / 100)) + deliveryCharges;

   // console.log( newTotal)
    setTotalIncludingDelivery(newTotal);
};

const order = {
  shippingInfo,
  orderItems: cartItems.map(item => ({
      ...item,
      price: (item.price - (item.price * (discount / 100))).toFixed(2)
  })),
  totalPrice: totalIncludingDelivery.toFixed(2)
};
   

    useEffect(() => {
   
        if (error) {
            dispatch(clearErrors());
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [dispatch, error, enqueueSnackbar]);


const onPaymentSuccess = async (details) => {

        //console.log("Payment Details:", details);
        if (details.status === "COMPLETED") {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                   
                };

                // Konstruktion des paymentInfo-Objekts basierend auf den PayPal-Details

            // Constructing the paymentInfo object based on the PayPal details
            const paymentInfo = {
                id: details.id,
                status: details.status,
                amount: details.purchase_units[0].amount.value, // Using the formatted amount here
                currency: details.purchase_units[0].amount.currency_code,
                email: userEmail,
            };
            
                //axios.defaults.withCredentials = true
                // Hinzufügen des paymentInfo-Objekts zum order-Objekt
                const orderWithPaymentInfo = {
                    ...order,
                    user,
                    paymentInfo,
                };
    
                // Versenden des order-Objekts mit dem neuen paymentInfo an das Backend
                 await axios.post('/api/v1/paypal/callback', paymentInfo, config);

    
                //console.log('Zahlung erfolgreich gespeichert', response.data);
                setPaymentSuccess(true); // Stelle sicher, dass setPaymentSuccess korrekt definiert und verwendet wird
                enqueueSnackbar("Zahlung erfolgreich!", { variant: paymentInfo.id });
    
                // Weiterleiten und weitere Aktionen
               dispatch(newOrder(orderWithPaymentInfo));
            

                ////console.log(newOrder(orderWithPaymentInfo));
               dispatch(emptyCart());
               //console.log(order);
               navigate("/orders/success", { state: { order:order }});
             
           } catch (error) {
            //console.error('Fehler beim Speichern der Zahlung', error);
            enqueueSnackbar("Fehler bei der Verarbeitung der Zahlung.", { variant: "error" });
        } finally {
        }
    } else {
        enqueueSnackbar("Processing Payment Failed!", { variant: "error" });
    }
};
    
return (
  <>
      <MetaData title="Holzwurm: Secure Payment | PayPal" />
      <main className="w-full mt-20 pt-10">
          <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mx-auto my-4 sm:mb-7">
              <div className="flex-1">
                  <Stepper activeStep={3}>
                      <Box className="bg-white py-4 px-8 shadow rounded-lg mb-6">
                          <Typography variant="h6" gutterBottom>
                              Gutschein einlösen
                          </Typography>
                          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                          <TextField
                              label="Coupon Code"
                              variant="outlined"
                              fullWidth
                              value={couponCode}
                              onChange={(e) => setCouponCode(e.target.value)}
                              margin="normal"
                              disabled={couponApplied}
                          />
                          {!couponApplied && (
                              <Button variant="contained" color="primary" onClick={applyCoupon}>
                                  Coupon überprüfen
                              </Button>
                          )}
                      </Box>
                      <Box className="bg-white py-4 px-8 shadow rounded-lg">
                          <Typography variant="h6" gutterBottom>
                              Zahlungsdetails
                          </Typography>
                          <PayPalScriptProvider options={initialOptions}>
                          {isReadyForPayment && (
    <PayPalButtons
        style={{ shape: "rect", layout: "vertical", color: "gold", label: "paypal" }}
        createOrder={(data, actions) => {
            const formattedTotal = Number(totalIncludingDelivery.toFixed(2));
          //  console.log(order)
          //  console.log("Sending to PayPal:", formattedTotal);
            return actions.order.create({
                purchase_units: [{
                    amount: { value: formattedTotal.toString() },
                }],
            });
        }}
        onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
                onPaymentSuccess(details);
            });
        }}
    />
)}


                          </PayPalScriptProvider>
                      </Box>
                  </Stepper>
              </div>
              <PriceSidebar cartItems={cartItems} total={totalIncludingDelivery} deliveryCharges={deliveryCharges}  />
              
          </div>
      </main>
  </>
);
};

export default Payment;