import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { TextField, Button, Paper } from '@mui/material';
import axios from 'axios';

const CouponForm = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState('');
    const [validity, setValidity] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (discount < 0 || discount > 100) {
            enqueueSnackbar("Der Rabatt muss zwischen 0% und 100% liegen", { variant: "error" });
            return;
        }
    
        try {
            await axios.post('/api/v1/admin/coupons', { 
                code: couponCode, 
                discount, 
                expireAt: validity 
            });
            enqueueSnackbar("Coupon erfolgreich erstellt!", { variant: "success" });
            // Reset Form
            setCouponCode('');
            setDiscount('');
            setValidity('');
        } catch (error) {
            console.error('Fehler beim Erstellen des Coupons:', error);
            enqueueSnackbar("Fehler beim Erstellen des Coupons.", { variant: "error" });
        }
    };
    
    return (
        <Paper elevation={3} style={{ padding: "20px", maxWidth: "500px", margin: "auto", backgroundColor: "white" }}>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Coupon Code"
                    variant="outlined"
                    fullWidth
                    required
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Rabatt (%)"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                    margin="normal"
                />
                <TextField
                    label="Gültigkeit (Datum)"
                    type="date"
                    variant="outlined"
                    fullWidth
                    required
                    value={validity}
                    onChange={(e) => setValidity(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                />
                <Button type="submit" color="primary" variant="contained" fullWidth style={{ marginTop: "20px" }}>
                    Coupon Erstellen
                </Button>
            </form>
        </Paper>
    );
};

export default CouponForm;
