import React from 'react';
import './ReturnPolicy.css';
const ReturnPolicy = () => {
  return (
    <div className="return-policy">
      <h2>Rückgaberecht</h2>
      <p>Liebe Kunden und Kundinnen von Holzwurm, Ihr Zufriedenheit liegt uns am Herzen. Sollten Sie mit Ihrem Kauf nicht vollständig zufrieden sein, bieten wir Ihnen die Möglichkeit, Produkte innerhalb von 14 Tagen nach Erhalt zurückzugeben.</p>
      
      <h3>Rückgabebedingungen</h3>
      <p>Um eine vollständige Rückerstattung zu erhalten, muss das Spielzeug unbenutzt und in seinem ursprünglichen Zustand sein. Die Rückgabe muss ebenfalls in der Originalverpackung erfolgen.</p>
      
      <h3>Ausnahmen</h3>
      <p>Bitte beachten Sie, dass bestimmte Artikel vom Umtausch ausgeschlossen sind:</p>
      <ul>
        <li>Personalisierte Produkte, die nach Ihren Spezifikationen angefertigt wurden</li>
        <li>Produkte, die aus hygienischen Gründen nicht zurückgegeben werden können</li>
        <li>Verkaufsartikel</li>
      </ul>
      
      <h3>Rückgabeprozess</h3>
      <p>Um eine Rückgabe einzuleiten, kontaktieren Sie uns bitte unter <a href="mailto:info@holzwurm-ab.de">info@holzwurm-ab.de</a> mit Ihrem Kaufbeleg und dem Grund der Rückgabe. Wir werden Ihnen dann Anweisungen zur Rücksendung des Produkts geben.</p>
      
      <h3>Rückerstattungen</h3>
      <p>Nach Erhalt und Prüfung der Rücksendung benachrichtigen wir Sie über den Status Ihrer Rückerstattung. Wenn diese genehmigt wird, wird die Erstattung über Ihre ursprüngliche Zahlungsmethode veranlasst.</p>
      
      <h3>Fragen?</h3>
      <p>Wenn Sie Fragen zu Ihrer Bestellung oder unserer Rückgabepolitik haben, zögern Sie nicht, uns zu kontaktieren. Wir helfen Ihnen gerne weiter.</p>
    </div>
  );
};

export default ReturnPolicy;
