//import Auto from '../../assets/images/Categories/Auto.png';
//import bau from '../../assets/images/Categories/bau.png';
//import Puppen from '../../assets/images/Categories/Pupen.png';
//import Kartenspiele from '../../assets/images/Categories/kartenspiele.png';
//import Lernspielzeug from '../../assets/images/Categories/Lernspielzeug.png';
//import Outdoor from '../../assets/images/Categories/outdor.png';
//import Bastelmaterialien from '../../assets/images/Categories/bastel.png';
//import Musikinstrumente from '../../assets/images/Categories/instroment.png';
//import verkleidung from '../../assets/images/Categories/verkleidung.png';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
const catNav = [

    {
        name: "Baby",
        //icon: Auto,
    },

    {
        name: "Kleinkind",
        //icon: bau,
    },
    {
        name: "Spielzeuge",
        //icon: Puppen,
    },
    {
        name: "Puppen",
        //icon: Kartenspiele,
    },

    {
        name: "Elektronik",
        //icon: Outdoor,
    },
    {
        name: "Spiele",
        //icon: Bastelmaterialien,
    },
    {
        name: "Schule",
        //icon: Musikinstrumente,
    },
    {
        name: "Kuscheltiere",
        //icon: verkleidung,
    },
    {
      name: "Taschen",
      //icon: Lernspielzeug,
  },
  {
    name: "Bücher",
    //icon: Lernspielzeug,
},
{
  name: "Basteln",
  //icon: Lernspielzeug,
},
{
  name: "Sport",
  //icon: Lernspielzeug,
},
{
  name: "Outdoor",
  //icon: Lernspielzeug,
},
{
  name: "Fashion",
  //icon: Lernspielzeug,
},
{
  name: "Erwachsene",
  //icon: Lernspielzeug,
},
]
// Beispiel für die Categories Komponente

const Categories = () => {
  //const [isMenuOpen, setIsMenuOpen] = useState(false);
  //const toggleMenu = () => {
  //  setIsMenuOpen(!isMenuOpen);
  //};
  
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  let navigate = useNavigate();

  const handleCategoryClick = (categoryName) => {
    // Schließe das Menü, wenn eine Kategorie ausgewählt wird
    setIsMobileMenuOpen(false); // Diese Zeile hinzufügen

    // Erzwinge das Navigieren zu einer neuen Seite, selbst wenn auf derselben Route
    navigate(`/products?category=${categoryName}`);
  };

  // Diese Funktion wechselt den Zustand des mobilen Menüs zwischen offen und geschlossen
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <>
      {/* Mobilgerät-Menü-Button */}
     {/* Mobilgerät-Menü-Button */}
     <div className=" sm:hidden flex justify-end  overflow-hidden pt-20">
        <button onClick={toggleMobileMenu}>
          <MenuIcon />
          <span className="text-sm text-gray-800 font-medium">Kategorien</span>
        </button>
      </div>

      {/* Mobilgerät-Menü */}
      {isMobileMenuOpen && (
       
        <div className="absolute top-full left-0 w-full bg-primary-white2 shadow-md z-50 flex flex-col items-center sm:hidden">
        {catNav.map((item, index) => (
            <div className="group relative p-auto" key={index} onClick={() => handleCategoryClick(item.name)}>
              <Link to={`/products?category=${item.name}`} className="flex flex-col items-center py-1">
                {/* Kategorie-Name */}
                <span className="py-0 px-4 w-full text-left">{item.name}</span>
           
              </Link>
            </div>
          ))}
        </div>
      )}

<section className="hidden sm:block md:block lg:block bg-primary-white2 mt-10 min-w-full px-12 py-auto shadow overflow-hidden">  
  <div className="flex justify-between mt-4 category-item">
    {catNav.map((item, index) => (
      <div className="group relative p-auto" key={index} onClick={() => handleCategoryClick(item.name)}>
        <Link to={`/products?category=${item.name}`} className="flex flex-col items-center py-1">
          {/* Kategorie-Name with hover effects */}
          <span className="relative ml-auto mr-1 text-xsm text-gray-800 font-medium top-0 left-1/2 transform -translate-x-1/2 translate-y-0 group-hover:-translate-y-full group-hover:text-primary-blue group-hover:font-semibold transition-all duration-300 ease-in-out cursor-pointer">
            {item.name}
          </span>
          {/* Bild-Container */}
        </Link>
      </div>
    ))}
  </div>
</section>





    </>
  );
};

export default Categories;