import React, { useEffect, useState } from 'react';
import './CookieConsentBanner.css'; // Stelle sicher, dass du eine entsprechende CSS-Datei erstellst

const CookieConsentBanner = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent !== 'true') {
      setShowCookieConsent(true);
    }
  }, []);

  const handleAcceptCookie = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowCookieConsent(false);
  };

  return showCookieConsent ? (
    <div className="cookie-banner">
      <p>Wir verwenden Cookies, um Ihre Erfahrung zu verbessern.<br></br>
      Indem Sie fortfahren, stimmen Sie den <a href="/terms">Nutzungsbedingungen</a> und dem <a href="/privacy">Datenschutz</a> zu.</p>
      <button onClick={handleAcceptCookie}>
        Akzeptieren
      </button>
    </div>
  ) : null;
};

export default CookieConsentBanner;
