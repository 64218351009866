import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import successfull from '../../assets/images/Transaction/success.png';
import failed from '../../assets/images/Transaction/failed.png';
import { useSelector } from 'react-redux'; // Wenn Sie Redux verwenden

const OrderSuccess = ({ success ,orderDetails }) => {
    const navigate = useNavigate();
    const [time, setTime] = useState(3);
    // Hier überprüfen wir den Nutzerstatus mit dem useSelector-Hook
    // Dies setzt voraus, dass Sie einen entsprechenden Nutzerstatus in Ihrem Redux-Store haben
    // Wenn Sie eine andere State-Management-Lösung verwenden, passen Sie dies entsprechend an
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        if (time === 0) {
            if (!user?.email) {
                // Wenn kein Nutzer eingeloggt ist (z.B. kein E-Mail im Nutzerobjekt), 
                // leiten Sie zum Login anstatt zu 'orders' oder 'cart'
                navigate('/after-order-success', { state: { order: orderDetails } });
            } else if (success) {
                navigate('/after-order-success', { state: { order: orderDetails } });
            } else {
                navigate("/cart");
            }
            return;
        };
        const intervalId = setInterval(() => {
            setTime(time - 1);
        }, 1000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [time, navigate, user,orderDetails]);

    return (
        <>
            <MetaData title={`Transaction ${success ? "Successfull" : "Failed"}`} />

            <main className="w-full mt-20">
                <div className="flex flex-col gap-2 items-center justify-center sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow rounded p-6 pb-12">
                    <img draggable="false" className="w-1/2 h-60 object-contain" src={success ? successfull : failed} alt="Transaction Status" />
                    <h1 className="text-2xl font-semibold">Transaktion {success ? "Erfolgreich" : "Fehlgeschlagen"}</h1>
                    <p className="mt-4 text-lg text-gray-800">Weiterleitung zu {success ? "orders" : "cart"} in {time} Sekunden</p>
                    <Link to={success ? "/orders" : "/cart"} className="bg-primary-blue mt-2 py-2.5 px-6 text-white uppercase shadow hover:shadow-lg rounded-sm">Gehe zu {success ? "orders" : "cart"}</Link>
                </div>
            </main>
        </>
    );
};

export default OrderSuccess;
