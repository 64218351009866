import React from 'react';
import './PrivacyPolicy.css'; // Angenommen, Sie haben eine CSS-Datei für Styling

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Datenschutzerklärung</h1>
            <p>Wir bei Holzwurm nehmen den Schutz Ihrer persönlichen Daten ernst. Diese Datenschutzerklärung erläutert, wie wir Ihre personenbezogenen Daten erfassen, verwenden und schützen, wenn Sie unsere Website besuchen oder Einkäufe in unserem Online-Shop tätigen.</p>
            
            <h2>Datenerfassung</h2>
            <p>Wir erfassen verschiedene Arten von Informationen für verschiedene Zwecke, um unseren Service an Sie zu erbringen und zu verbessern.</p>
            
            <h3>Persönliche Daten</h3>
            <p>Während der Nutzung unseres Services können wir Sie bitten, uns bestimmte persönlich identifizierbare Informationen zur Verfügung zu stellen, die für die Kontaktaufnahme oder Identifizierung verwendet werden können. Persönlich identifizierbare Informationen umfassen, sind aber nicht beschränkt auf:</p>
            <ul>
                <li>E-Mail-Adresse</li>
                <li>Vor- und Nachname</li>
                <li>Telefonnummer</li>
                <li>Adresse, Bundesland, Provinz, PLZ/Stadt</li>
            </ul>

            <h2>Datennutzung</h2>
            <p>Die erfassten Daten verwenden wir, um unseren Service bereitzustellen und zu verbessern, um Sie über Änderungen unseres Service zu informieren oder um Ihnen Kundensupport zu bieten.</p>

            <h2>Datenweitergabe</h2>
            <p>Ihre Daten werden ohne Ihre Zustimmung nicht an Dritte weitergegeben, verkauft oder vermietet, es sei denn, es ist gesetzlich erforderlich.</p>

            <h2>Datensicherheit</h2>
            <p>Die Sicherheit Ihrer Daten ist uns wichtig, aber denken Sie daran, dass keine Übertragungsmethode über das Internet oder Methode der elektronischen Speicherung 100% sicher ist. Während wir uns bemühen, kommerziell akzeptable Mittel zum Schutz Ihrer persönlichen Daten zu verwenden, können wir deren absolute Sicherheit nicht garantieren.</p>

            <h2>Änderungen dieser Datenschutzerklärung</h2>
            <p>Wir können unsere Datenschutzerklärung von Zeit zu Zeit aktualisieren. Wir werden Sie über alle Änderungen informieren, indem wir die neue Datenschutzerklärung auf dieser Seite veröffentlichen.</p>

            <h2>Kontaktieren Sie uns</h2>
            <p>Wenn Sie Fragen zu dieser Datenschutzerklärung haben, kontaktieren Sie uns bitte.</p>
        </div>
    );
};

export default PrivacyPolicy;
