import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'

import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, registerUser } from '../../actions/userAction';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import FormSidebar from './FormSidebar';

const Register = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [user, setUser] = useState({
        name: "",
        email: "",
        phoneNo: "",
        password: "",
        cpassword: "",
    });

    const { name, email, phoneNo, password, cpassword } = user;

    const [avatar, setAvatar] = useState();
    const [avatarPreview, setAvatarPreview] = useState("preview.png");

    const handleRegister = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            enqueueSnackbar("Password length must be atleast 8 characters", { variant: "warning" });
            return;
        }
        if (password !== cpassword) {
            enqueueSnackbar("Password Doesn't Match", { variant: "error" });
            return;
        }
       /* if (!avatar) {
            enqueueSnackbar("Select Avatar", { variant: "error" });
            return;
        }*/

        const formData = new FormData();
        formData.set("name", name);
        formData.set("email", email);
        formData.set("phoneNo", phoneNo);
        formData.set("password", password);
        formData.set("avatar", avatar);

        dispatch(registerUser(formData));
    }

    const handleDataChange = (e) => {
        if (e.target.name === "avatar") {
            if (e.target.files.length > 0) {
                const reader = new FileReader();
    
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setAvatarPreview(reader.result);
                        setAvatar(reader.result);
                    }
                };
    
                reader.readAsDataURL(e.target.files[0]);
            } else {
                setAvatarPreview(null);
                setAvatar("");
            }
        } else {
            setUser({ ...user, [e.target.name]: e.target.value });
        }
    }
    

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate('/')
        }
    }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="Register | Holzwurm" />

            {loading && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">

                {/* <!-- row --> */}
                <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">

                    <FormSidebar
                        title="Sieht so aus, als wärst du neu hier!"
                        tag="Melden Sie sich mit Ihrer Handynummer an, um loszulegen"
                    />

                    {/* <!-- signup column --> */}
                    <div className="flex-1 overflow-hidden">

                        {/* <!-- personal info procedure container --> */}
                        <form
                            onSubmit={handleRegister}
                            encType="multipart/form-data"
                            className="p-5 sm:p-10"
                        >
                            <div className="flex flex-col gap-4 items-start">

                                {/* <!-- input container column --> */}
                                <div className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
                                <TextField
    fullWidth
    id="full-name"
    label="Vollständiger Name"
    name="name"
    value={name}
    onChange={handleDataChange}
    required
    autoComplete="name" // Verbessert die Autovervollständigung für den Namen
/>
<TextField
    fullWidth
    id="email"
    label="Email"
    type="email"
    name="email"
    value={email}
    onChange={handleDataChange}
    required
    autoComplete="email" // Verbessert die Autovervollständigung für die E-Mail-Adresse
/>
<TextField
    fullWidth
    label="Handynummer"
    type="tel"
    name="phoneNo"
    value={phoneNo}
    onChange={handleDataChange}
    required
    autoComplete="tel" // Verbessert die Autovervollständigung für Telefonnummern
/>
<TextField
    id="password"
    label="Password"
    type="password"
    name="password"
    value={password}
    onChange={handleDataChange}
    required
    autoComplete="new-password" // Gibt an, dass ein neues Passwort erwartet wird
/>
<TextField
   id="confirm-password"
   label="Bestätige das Passwort"
   type="password"
   name="cpassword"
   value={cpassword}
   onChange={handleDataChange}
   required
    autoComplete="new-password" // Wiederholung für das neue Passwort, gleiche Verwendung von "new-password" ist hier angemessen
/>

                                </div>
                                {/* <!-- input container column --> */}

                                <div className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
                                    <Avatar
                                        alt="Avatar Preview"
                                        src={avatarPreview}
                                        sx={{ width: 56, height: 56 }}
                                    />
                                    <label className="rounded font-medium bg-gray-400 text-center cursor-pointer text-white w-full py-2 px-2.5 shadow hover:shadow-lg">
                                        <input
                                            type="file"
                                            name="avatar"
                                            accept="image/*"
                                            onChange={handleDataChange}
                                            className="hidden"
                                        />
                                       Datei wählen
                                    </label>
                                </div>
                                <button type="submit" className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium">Melden Sie sich an</button>
                                <Link to="/login" className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium">Bereit Benutzer? Anmeldung</Link>
                            </div>

                        </form>
                        {/* <!-- personal info procedure container --> */}

                    </div>
                    {/* <!-- signup column --> */}
                </div>
                {/* <!-- row --> */}

            </main>
        </>
    );
};

export default Register;
