import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Banner.css';
import { PopupButton } from "react-calendly";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import bild1 from '../../../assets/images/Banners/1.jpg';
//import bild2 from '../../../assets/images/Banners/2.jpg';
//import bild3 from '../../../assets/images/Banners/10.jpg';
//import bild4 from '../../../assets/images/Banners/4.jpg';
//import bild5 from '../../../assets/images/Banners/5.jpg';
//import bild6 from '../../../assets/images/Banners/6.jpg';
//import bild7 from '../../../assets/images/Banners/7.jpg';
//import bild8 from '../../../assets/images/Banners/8.jpg';
//import bild9 from '../../../assets/images/Banners/9.jpg';

export const PreviousBtn = ({ className, onClick }) => {
  return (
    <div className={`${className} custom-arrow custom-arrow-prev`} onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  )
}

export const NextBtn = ({ className, onClick }) => {
  return (
    <div className={`${className} custom-arrow custom-arrow-next`} onClick={onClick}>
      <ArrowForwardIosIcon />
    </div>
  )
}

const Banner = () => {

  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
  };

  const banners = [bild1 ,bild1];
  return (
    <>
      <section className="h-64 sm:h-72 w-full rounded-sm shadow relative overflow-hidden">
        <Slider {...settings}>
          {banners.map((el, i) => (
            <div key={i} className="relative w-full">
              <img draggable="false" className="h-44 sm:h-72 w-full object-cover" src={el} alt="banner" />
              <div className="absolute inset-0 flex flex-col justify-center items-center App">
                <span className="mb-2 text-xl text-white px-4 py-2 bg-black bg-opacity-50 rounded" style={{ fontWeight: 'bold' }}>
                  Schulranzen
                </span>
                <span className="mb-2 text-xl text-white px-10 py-2 bg-black bg-opacity-50 rounded flex items-center justify-center a">
  Buchen Sie jetzt Ihren vor Ort Beratungstermin.
</span>

         
                <PopupButton className="px-4 py-2 rounded bg-blue-500 text-white text-lg hover:bg-blue-700 transition duration-300"
        url="https://book.timify.com/services?accountId=5ff2dd806f89e21090f01d61&hideCloseButton=true"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Beratungstermin jetzt buchen"
      />
              </div>
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
};

export default Banner;
