// AboutPage.js
import React from 'react';
import './AboutPage.css'; // Stellen Sie sicher, dass Sie Ihren CSS-Dateipfad entsprechend anpassen

const AboutPage = () => {
    return (
        <div className="about-us-container">
          <h2>Über Uns</h2>
          <p><strong>Willkommen bei Holzwurm</strong> – Ihr Spezialist für nachhaltiges Spielzeug!</p>
          <p>Inmitten der malerischen Altstadt von Aschaffenburg, umgeben von Tradition und Geschichte, öffnete Holzwurm im Jahre 2010 seine Türen. Was als kleiner Familienbetrieb mit der Leidenschaft für handgefertigtes, nachhaltiges Spielzeug begann, hat sich zu einem beliebten Treffpunkt für Familien, Sammler und Liebhaber hochwertiger Holzspielwaren entwickelt.</p>
          
          <h3>Unsere Philosophie</h3>
          <p>Bei Holzwurm steht die Liebe zum Detail, die Qualität der Materialien und die Freude am gemeinsamen Spiel im Vordergrund. Wir glauben, dass Spielzeug mehr ist als nur ein Zeitvertreib. Es ist ein Werkzeug für Kreativität, Lernen und persönliche Entwicklung. Deshalb wählen wir jedes Stück in unserem Sortiment sorgfältig aus, um sicherzustellen, dass es nicht nur Spaß macht, sondern auch einen pädagogischen Wert bietet und die Phantasie anregt.</p>
          
          <h3>Nachhaltigkeit</h3>
          <p>Nachhaltigkeit ist uns ein Herzensanliegen. Wir setzen auf Holz aus verantwortungsvoller Forstwirtschaft und arbeiten eng mit Handwerkern zusammen, die traditionelle Fertigungsmethoden und umweltschonende Techniken nutzen. Unser Ziel ist es, den kleinstmöglichen ökologischen Fußabdruck zu hinterlassen und gleichzeitig Spielzeug zu bieten, das Generationen überdauert.</p>
          
          <h3>Einzigartigkeit</h3>
          <p>In einer Zeit, in der Massenproduktion und schneller Konsum vorherrschen, setzen wir auf Einzigartigkeit und Beständigkeit. Jedes Spielzeug bei Holzwurm erzählt eine eigene Geschichte, gefertigt von talentierten Handwerkern aus der Region und ausgewählten Teilen der Welt. Ob klassische Holzeisenbahnen, kreative Puzzle oder pädagogische Spiele – bei uns finden Sie Schätze, die in keinem gewöhnlichen Laden zu finden sind.</p>
          
          <h3>Unsere Gemeinschaft</h3>
          <p>Holzwurm ist mehr als nur ein Laden. Es ist ein Ort der Begegnung, an dem wir Workshops, Spielabende und Veranstaltungen für Kinder und Eltern anbieten. Wir glauben an die Kraft der Gemeinschaft und daran, gemeinsame Erinnerungen zu schaffen, die ein Leben lang halten.</p>
          
          <p><strong>Treten Sie ein in eine Welt voller Fantasie und Entdeckungen</strong></p>
          <p>Wir laden Sie herzlich ein, uns in unserem Laden zu besuchen oder in unserem Online-Shop zu stöbern. Entdecken Sie die Welt von Holzwurm, wo jedes Spielzeug eine Seele hat und jede Kreation eine Einladung zum Träumen und Entdecken ist.</p>
          <p>Herzlich Willkommen in der Familie Holzwurm – wo Spiel, Spaß und Nachhaltigkeit zu Hause sind.</p>
        </div>
      );
    };
    

export default AboutPage;
