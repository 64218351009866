import { getDiscount } from '../../../utils/functions';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../../actions/wishlistAction';
import { useSnackbar } from 'notistack';
import new1 from '../../../assets/images/neu.png';

const Product = (props) => {

    const { _id, name,highlights, images, ratings, numOfReviews, price, cuttedPrice,brand } = props;

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { wishlistItems } = useSelector((state) => state.wishlist);
    
    const itemInWishlist = wishlistItems.some((i) => i.product === _id);

    const addToWishlistHandler = () => {
        if(itemInWishlist) {
            dispatch(removeFromWishlist(_id));
            enqueueSnackbar("Remove From Wishlist", { variant: "success" });
        } else {
            dispatch(addToWishlist(_id));
            enqueueSnackbar("Added To Wishlist", { variant: "success" });
        }
    }

    return (
        <div className="flex flex-col items-center gap-2 px-2 py-6 relative">
            {/* <!-- image & product title --> */}
            <Link to={`/product/${_id}`} className="flex flex-col items-center text-center group">
                <div className="w-36 h-36">
                    <img draggable="false" className="w-full h-full object-contain" src={images[0].url} alt={name} />
                </div>
                <h2 className="text-sm mt-4 group-hover:text-primary-blue">{name.length > 50 ? `${name.substring(0, 50)}...` : name}</h2>
                <span>  <span className="text-sm mt-4 group-hover:text-primary-lightGreen text-primary-orange ">{brand.name}</span></span>

                {highlights && highlights.includes('NEU') && (
      <div className="absolute bottom-25 right-10 m-2 shadow-lg text-white px-2 py-1 rounded">
      <img draggable="false" className="w-16 h-16 object-contain" src={new1} alt={name} />
  </div>
  
        )}
            </Link>
            {/* <!-- image & product title --> */}

            {/* <!-- product description --> */}
            <div className="flex flex-col gap-2 items-center">
                {/* <!-- rating badge --> */}
                <span className="text-sm text-gray-500 font-medium flex gap-2 items-center">
                    <span className="text-xs px-1.5 py-0.5 bg-primary-green rounded-sm text-white flex items-center gap-0.5">{ratings.toFixed(1)} <StarIcon sx={{ fontSize: "14px" }} /></span>
                    <span>({numOfReviews.toLocaleString()})</span>
                </span>
                {/* <!-- rating badge --> */}

                {/* <!-- price container --> */}
                <div className="flex items-center gap-1.5 text-md font-medium">
    <span>{price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }).replace('€', '€')}</span>
    {
      cuttedPrice !== 0 && price < cuttedPrice ? (
        <span className="text-gray-500 line-through text-xs">{cuttedPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }).replace('€', '€')}</span>
      ) : null
    }
    {
      cuttedPrice !== 0 && price < cuttedPrice ? (
        <span className="text-base text-primary-green">
          {getDiscount(price, cuttedPrice)}%&nbsp;reduziert
        </span>
      ) : null
    }
</div>
                {/* <!-- price container --> */}
            </div>
            {/* <!-- product description --> */}

            {/* <!-- wishlist badge --> */}
            <span onClick={addToWishlistHandler} className={`${itemInWishlist ? "text-red-500" : "hover:text-red-500 text-gray-300"} absolute top-5 right-2 cursor-pointer`}><FavoriteIcon sx={{ fontSize: "16px" }} /></span>
            {/* <!-- wishlist badge --> */}

        </div>
    );
};

export default Product;
