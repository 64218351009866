import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import './Sidebar.css';
import { useSnackbar } from 'notistack';
import { logoutUser } from '../../../actions/userAction';
import defaultAvatar from '../../../assets/avatar.png';
const navMenu = [
    {
        icon: <EqualizerIcon />,
        label: "Dashboard",
        ref: "/admin/dashboard",
    },
    {
        icon: <ShoppingBagIcon />,
        label: "Bestellungen",
        ref: "/admin/orders",
    },
    {
        icon: <InventoryIcon />,
        label: "Produkte",
        ref: "/admin/products",
    },
    {
        icon: <AddBoxIcon />,
        label: "Produkt hinzufügen",
        ref: "/admin/new_product",
    },
    {
        icon: <GroupIcon />,
        label: "Benutzer",
        ref: "/admin/users",
    },
    {
        icon: <ReviewsIcon />,
        label: "Bewertungen",
        ref: "/admin/reviews",
    },
    {
        icon: <LocalOfferIcon />,
        label: "Coupons ",
        ref: "/admin/Coupons",
    },
    {
        icon: <AddBoxIcon />,
        label: "Coupons hinzufügen",
        ref: "/admin/new_coupons",
    },
    {
        icon: <AccountBoxIcon />,
        label: "Mein Profil",
        ref: "/account",
    },
    {
        icon: <LogoutIcon />,
        label: "Ausloggen",
    },
];

const Sidebar = ({ activeTab, setToggleSidebar }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useSelector((state) => state.user);

    const handleLogout = () => {
        dispatch(logoutUser());
        enqueueSnackbar("Logout Successfully", { variant: "success" });
        navigate("/login");
    }

    return (
        <aside className="sidebar z-10 sm:z-0 block min-h-screen fixed left-0 pb-14 max-h-screen w-3/7 sm:w-1/10 bg-gray-800 text-white overflow-x-hidden border-r">
            {/* User information and close button */}
            <div className="flex items-center gap-3 bg-gray-700 p-2 rounded-lg shadow-lg my-4 mx-3.5">
                <Avatar alt="Avatar" src={user.avatar.url || defaultAvatar} />
                <div className="flex flex-col gap-0">
                    <span className="font-medium text-lg">{user.name}</span>
                    <span className="text-gray-300 text-sm">{user.email}</span>
                </div>
                <button onClick={() => setToggleSidebar(false)} className="sm:hidden bg-gray-800 ml-auto rounded-full w-10 h-10 flex items-center justify-center">
                    <CloseIcon />
                </button>
            </div>

            {/* Navigation menu */}
            <div className="flex flex-col w-full gap-0 my-8">
                {navMenu.map((item, index) => (
                    <React.Fragment key={index}> {/* Unique key for each child */}
                        {item.label === "Ausloggen" ? (
                            <button onClick={handleLogout} className="hover:bg-gray-700 flex gap-3 items-center py-3 px-4 font-medium text-white">
                                {item.icon}
                                <span>{item.label}</span>
                            </button>
                        ) : (
                            <Link to={item.ref} className={`${activeTab === index ? "bg-gray-700" : "hover:bg-gray-700"} flex gap-3 items-center py-3 px-4 font-medium`}>
                                {item.icon}
                                <span>{item.label}</span>
                            </Link>
                        )}
                    </React.Fragment>
                ))}
            </div>

            {/* Footer or additional section */}
            <div className="flex flex-col gap-1 bg-gray-700 p-3 rounded-lg shadow-lg mb-6 mt-28 mx-3.5 overflow-hidden">
                <h5>Developed with ❤️ by:</h5>
                <div className="flex flex-col gap-0">
                    <a href="https://www.linkedin.com/in/anas-karah" target="_blank" rel="noreferrer" className="font-medium text-lg hover:text-blue-500">Anas Karah</a>
                    <a href="mailto:ak@code-craft.tech" className="text-gray-300 text-sm hover:text-blue-500">ak@code-craft.tech</a>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
