import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import PriceSidebar from './PriceSidebar';
import Stepper from './Stepper';
import { useSnackbar } from 'notistack';
import { saveShippingInfo } from '../../actions/cartAction';
import { useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import states from '../../utils/states';
import React, { useState, useEffect } from 'react';
  
  const Shipping = () => {

    const { user } = useSelector((state) => state.user);

    const [name, setName] = useState(user?.name || '');
     // Zustände für Rechnungsadresse
     const [billingName, setBillingName] = useState('');
     const [billingAddress, setBillingAddress] = useState('');
     const [billingCity, setBillingCity] = useState('');
     const [billingState] = useState('');
     const [billingPincode, setBillingPincode] = useState('');
     const [billingPhoneNo, setBillingPhoneNo] = useState('');
 
     const [differentBillingAddress, setDifferentBillingAddress] = useState(false);
 
     const handleBillingAddressChange = (event) => {
         setDifferentBillingAddress(event.target.checked);
     };
 //   const [shippingMethod, setShippingMethod] = useState('delivery'); // 'delivery' oder 'clickAndCollect'

const guestInfo = {
    
    name: '', // Standardwert, falls Sie einen voreingestellten Wert möchten,
    address: '', // Standardwert, falls Sie einen voreingestellten Wert möchten, fügen Sie ihn hier ein
    city: '',
    country: 'DE',
    state: '', // Stellen Sie sicher, dass dies einem Ihrer Zustandscodes entspricht, wenn Sie einen Standardwert festlegen
    pincode: '',
    phoneNo: '', // Vom Gast bereitgestellte Telefonnummer als Beispiel
  };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { cartItems, shippingInfo } = useSelector((state) => state.cart);
    // Initialisiere den Zustand mit shippingInfo aus dem Redux Store oder, falls nicht vorhanden, mit guestInfo
    const [address, setAddress] = useState(shippingInfo.address || guestInfo.address);
    const [city, setCity] = useState(shippingInfo.city || guestInfo.city);
    const [country] = useState(shippingInfo.country || guestInfo.country);
    const [state, setState] = useState(shippingInfo.state || guestInfo.state);
    const [pincode, setPincode] = useState(shippingInfo.pincode || guestInfo.pincode);
    const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo || guestInfo.phoneNo);
    const { isAuthenticated } = useSelector((state) => state.user);
    const [guestEmail] = useState(localStorage.getItem('guestEmail') );
    //console.log(localStorage.getItem('guestEmail'));
    const isGuest = !user?.email;
  
    useEffect(() => {
     
        // Aktualisieren des Zustands, wenn die E-Mail in der Local Storage gefunden wird
        if (isGuest && guestEmail) {
            localStorage.setItem('guestEmail', guestEmail);
        }
    }, [guestEmail, isGuest]);
    const shippingSubmit = (e) => {
        e.preventDefault();
   
        if (phoneNo.length < 9 || phoneNo.length > 20) {
            enqueueSnackbar("Invalid Phone Number", { variant: "error" });
            return;
        }
        const billingInfo = differentBillingAddress ? {
            billingName,
            billingAddress,
            billingCity,
            billingState,
            billingPincode,
            billingPhoneNo
        } : {
            billingName: name,
            billingAddress: address,
            billingCity: city,
            billingState: state,
            billingPincode: pincode,
            billingPhoneNo: phoneNo
        };
 
    
        // Erstellen des Gesamtpayloads für den Versand
        const completeShippingInfo = {
            name,
            address,
            city,
            country,
            state,
            pincode,
            phoneNo,
            ...billingInfo // Übertragung der Rechnungsadressdaten
        };
    
        dispatch(saveShippingInfo(completeShippingInfo));
        navigate("/order/confirm");
    }
    const continueAsGuestHandler = () => {
        // Markieren des Benutzers als Gast im lokalen Speicher
        localStorage.setItem('isGuest', 'true');
        navigate('/login?redirect=shipping');
    };
    return (
        <>
            <MetaData title="Holzwurm: Shipping Details" />
            <main className="w-full mt-20 pt-10">

                {/* <!-- row --> */}
                <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7 overflow-hidden">

                    {/* <!-- cart column --> */}
                    <div className="flex-1">

                        <Stepper activeStep={1}>
                            <div className="w-full bg-white">

                                <form onSubmit={shippingSubmit} autoComplete="off" className="flex flex-col justify-start gap-3 w-full sm:w-3/4 mx-1 sm:mx-8 my-4">
                                <TextField
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                    label="Name des Empfängers"
                                    variant="outlined"
                                    required
                                />
                                    <TextField
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        fullWidth
                                        label="Adresse"
                                        variant="outlined"
                                        required
                                    />

                                    <div className="flex gap-6">
                                        <TextField
                                            value={pincode}
                                            onChange={(e) => setPincode(e.target.value)}
                                            type="number"
                                            label="PLZ"
                                            fullWidth
                                            variant="outlined"
                                            required
                                        />
                                        <TextField
                                            value={phoneNo}
                                            onChange={(e) => setPhoneNo(e.target.value)}
                                            type="number"
                                            label="Telefon-Nr"
                                            fullWidth
                                            variant="outlined"
                                            required
                                        />
                                    </div>

                                    <div className="flex gap-6">
                                        <TextField
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            label="Stadt"
                                            fullWidth
                                            variant="outlined"
                                            required
                                        />
                                        <TextField
                                            label="Zusatz Info. (Optional)"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </div>

                                    <div className="flex gap-6">

                                        <FormControl fullWidth>
                                            <InputLabel id="country-select">Land</InputLabel>
                                            <Select
                                                labelId="country-select"
                                                id="country-select"
                                                defaultValue={country}
                                                disabled
                                                label="Country"
                                                // onChange={(e) => setCountry(e.target.value)}
                                            >
                                                <MenuItem value={'DE'}>Germany</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth disabled={country ? false : true}>
                                            <InputLabel id="state-select">Bundesland</InputLabel>
                                            <Select
    labelId="state-select"
    id="state-select"
    value={state}
    label="State"
    onChange={(e) => setState(e.target.value)}
    required
>
    {states.map((item) => (
        <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem> // Hinzufügen des `key`-Attributs hier
    ))}
</Select>

                                        </FormControl>

                                    </div>
                                  
                               {/* Checkbox für unterschiedliche Rechnungsadresse */}
                               <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={differentBillingAddress}
                                                onChange={handleBillingAddressChange}
                                                name="differentBillingAddress"
                                            />
                                        }
                                        label="Rechnungsadresse entspricht nicht der Lieferadresse"
                                    />

                                    {/* Bedingte Felder für Rechnungsadresse */}
                                    {differentBillingAddress && (
                                        <>
                                            <TextField
                                                value={billingName}
                                                onChange={(e) => setBillingName(e.target.value)}
                                                fullWidth
                                                label="Name des Rechnungsempfängers"
                                                variant="outlined"
                                                required
                                            />
                                            <TextField
                                                value={billingAddress}
                                                onChange={(e) => setBillingAddress(e.target.value)}
                                                fullWidth
                                                label="Rechnungsadresse"
                                                variant="outlined"
                                                required
                                            />
                                            <TextField
                                                value={billingCity}
                                                onChange={(e) => setBillingCity(e.target.value)}
                                                fullWidth
                                                label="Stadt der Rechnungsadresse"
                                                variant="outlined"
                                                required
                                            />
                                            <TextField
                                                value={billingPincode}
                                                onChange={(e) => setBillingPincode(e.target.value)}
                                                type="number"
                                                fullWidth
                                                label="PLZ der Rechnungsadresse"
                                                variant="outlined"
                                                required
                                            />
                                            <TextField
                                                value={billingPhoneNo}
                                                onChange={(e) => setBillingPhoneNo(e.target.value)}
                                                type="number"
                                                fullWidth
                                                label="Telefon-Nr der Rechnungsadresse"
                                                variant="outlined"
                                               
                                            />
                                        </>
                                    )}
                                                    <div className="flex flex-col sm:flex-row justify-between items-center px-2 sm:px-8 py-4">
                                    <button type="submit" className="bg-primary-orange w-full sm:w-1/3 my-2 py-3.5 text-sm font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none">Hier speichern und liefern</button>
                                    {!isAuthenticated && (
                                    <button onClick={continueAsGuestHandler} className={`${cartItems.length < 1 ? "bg-primary-grey cursor-not-allowed" : "bg-primary-green"} w-full sm:w-auto px-6 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm`}>Login</button>
                                )}
                                 </div>
                                </form>
                            </div>
                            
                        </Stepper>
                    </div>
                    <PriceSidebar cartItems={cartItems} />
                </div>

            </main>
        </>
    );
};

export default Shipping;
