import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatIcon from '@mui/icons-material/Chat';

import NotificationsIcon from '@mui/icons-material/Notifications';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logoutUser } from '../../../actions/userAction';

const PrimaryDropDownMenu = ({ setTogglePrimaryDropDown, user }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { wishlistItems } = useSelector((state) => state.wishlist);

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate("/login");
        enqueueSnackbar("Logout Successfully", { variant: "success" });
        setTogglePrimaryDropDown(false);
    }

    const navs = [
  
       
        {
            title: "Bestellungen",
            icon: <ShoppingBagIcon sx={{ fontSize: "18px" }} />,
            redirect: "/orders",
        },
        {
            title: "Wunschzettel",
            icon: <FavoriteIcon sx={{ fontSize: "18px" }} />,
            redirect: "/wishlist",
        },
        {
            title: "Meine Chats",
            icon: <ChatIcon sx={{ fontSize: "18px" }} />,
            redirect: "/cart",
        },

        {
            title: "Benachrichtigungen",
            icon: <NotificationsIcon sx={{ fontSize: "18px" }} />,
            redirect: "/",
        },
    ]

    return (
        <div className="absolute w-60 top-9 ml-2 bg-white shadow-2xl rounded flex flex-col z-20 text-sm">
            {user.role === "admin" &&
                <Link className="pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50 rounded-t" to="/admin/dashboard" onClick={() => setTogglePrimaryDropDown(false)}>
                    <span className="text-primary-blue"><DashboardIcon sx={{ fontSize: "18px" }} /></span>
                    Admin Dashboard
                </Link>
            }
    
            <Link className="pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50" to="/account" onClick={() => setTogglePrimaryDropDown(false)}>
                <span className="text-primary-blue"><AccountCircleIcon sx={{ fontSize: "18px" }} /></span>
                Mein Profil
            </Link>
    
            {navs.map((item, index) => {
                const { title, icon, redirect } = item;
    
                return (
                    <Link key={index} className={`pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50 ${title === "Wunschzettel" ? "" : "rounded-b"}`} to={redirect} onClick={() => setTogglePrimaryDropDown(false)}>
                        <span className="text-primary-blue">{icon}</span>
                        {title}
                        {title === "Wunschzettel" && (
                            <span className="ml-auto mr-3 bg-gray-100 p-0.5 px-2 text-gray-600 rounded">
                                {wishlistItems.length}
                            </span>
                        )}
                    </Link>
                );
            })}
    
            <div className="pl-3 py-3.5 flex gap-3 items-center hover:bg-gray-50 rounded-b cursor-pointer" onClick={handleLogout}>
                <span className="text-primary-blue"><PowerSettingsNewIcon sx={{ fontSize: "18px" }} /></span>
                Logout
            </div>
    
            <div className="absolute right-1/2 -top-2.5">
                <div className="arrow_down"></div>
            </div>
        </div>
    );
    
};

export default PrimaryDropDownMenu;
