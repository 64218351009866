import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearErrors, getProducts } from '../../actions/productAction';
import Loader from '../Layouts/Loader';
//import MinCategory from '../Layouts/MinCategory';
import Product from './Product';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StarIcon from '@mui/icons-material/Star';
import { categories } from '../../utils/constants';
import MetaData from '../Layouts/MetaData';
//import { getRandomProducts } from '../../utils/functions';
import { useLocation } from 'react-router-dom';

const Products = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
       // console.log("Is Filter Visible:", !isFilterVisible); // Zum Debuggen
      };
      
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const location = useLocation();

    const [price, setPrice] = useState([0, 500]);
    const [category, setCategory] = useState(location.search ? location.search.split("=")[1] : "");    const [ratings, setRatings] = useState(0);

    // pagination
    const [currentPage, setCurrentPage] = useState(1);

    // filter toggles
    const [categoryToggle, setCategoryToggle] = useState(true);
    const [ratingsToggle, setRatingsToggle] = useState(true);

    const { products, loading, error,  resultPerPage, filteredProductsCount } = useSelector((state) => state.products);
    const keyword = params.keyword;

    const priceHandler = (e, newPrice) => {
        setPrice(newPrice);
    }

    const clearFilters = () => {
        setPrice([0, 500]);
        setCategory("");
        setRatings(0);
    }

    
    useEffect(() => {
       
        const query = new URLSearchParams(location.search);
        const categoryParam = query.get('category');
        if (categoryParam) {
          setCategory(categoryParam);
        } else {
          setCategory('');
        }
      }, [location.search]);
      useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        dispatch(getProducts(keyword, category, price, ratings, currentPage));
    
        // This assumes you have access to the total number of products from the fetch
        let newPageCount = Math.ceil(filteredProductsCount / resultPerPage);
        if (currentPage > newPageCount) {
            setCurrentPage(1); // Reset if current page exceeds the number of available pages
        }
    }, [dispatch, keyword, category, price, ratings, currentPage, error, enqueueSnackbar]);
    useEffect(() => {
        // This effect checks if the currentPage is still valid when the number of products changes
        let newPageCount = Math.ceil(filteredProductsCount / resultPerPage);
        if (currentPage > newPageCount) {
            setCurrentPage(1); // Reset if current page exceeds the number of available pages
        }
    
    }, [filteredProductsCount, resultPerPage, currentPage]);
    

    return (
      
      <>
      <MetaData title="All Products | Holzwurm" />
      <main className=" w-full mt-14 sm:mt-0 pt-24 ">
      <button
        className="sm:hidden bg-dark-gray text-white px-4 py-2 rounded-md shadow hover:bg-darkGray-700 transition-colors duration-200 ease-in-out"
        onClick={toggleFilterVisibility}
      >
        {isFilterVisible ? 'Filter verbergen' : 'Filter anzeigen'}
      </button>
      <div className="flex gap-3 mt-2 sm:mt-2 sm:mx-3 m-auto mb-7 pt-10 ">
          {/* Filter Toggle Button für Mobilansicht */}


          {/* Sidebar Column - Off-Canvas für Mobilansicht */}
          <div className={`fixed inset-0 transform ${isFilterVisible ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out z-50 sm:z-auto bg-white sm:relative sm:translate-x-0 sm:block px-1 ${isFilterVisible ? "block" : "hidden"} `}>            
          <div className="flex flex-col bg-white rounded-sm shadow">
            <div className="flex items-center justify-between gap-5 px-4 py-0 border-b">
               <p className="text-lg font-medium">Filter</p>
                  <span className="uppercase text-primary-blue text-xs cursor-pointer font-medium" onClick={() => clearFilters()}>alles löschen</span>
                  {/* Close Button für Mobilansicht */}
                  <button onClick={toggleFilterVisibility} className="sm:hidden">Anwenden</button>
              </div>


              <div className="flex flex-col gap-2 py-3 sm:py-1 text-sm overflow-y-auto max-h-[calc(120vh-185px)] ">

                                {/* price slider filter */}
                                <div className="flex flex-col gap-2 border-b px-4">
                                    <span className="font-medium text-xs">PREIS</span>

                                    <Slider
                                        value={price}
                                        onChange={priceHandler}
                                        valueLabelDisplay="auto"
                                        getAriaLabel={() => 'Price range slider'}
                                        min={0}
                                        max={500}
                                    />

                                    <div className="flex gap-3 items-center justify-between mb-2 min-w-full">
                                        <span className="flex-1 border px-4 py-0 rounded-sm text-gray-800 bg-gray-50">{price[0].toLocaleString()}€</span>
                                        <span className="font-medium text-gray-400">to</span>
                                        <span className="flex-1 border px-4 py-0 rounded-sm text-gray-800 bg-gray-50">{price[1].toLocaleString()}€</span>
                                    </div>
                                </div>
                                {/* price slider filter */}

                            
                                
                                
                               <div className="flex flex-col border-b px-4">
                                  
                               <div className="flex justify-between cursor-pointer py-0 pb-4 items-center" onClick={() => setCategoryToggle(!categoryToggle)}>
  <p className="font-medium text-xs uppercase ">Kategorie</p>
  {categoryToggle ?
      <ExpandLessIcon sx={{ fontSize: "20px" }} /> :
      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
  }
</div>

                                    {categoryToggle && (
                                        <div className="flex flex-col pb-1">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="category-radio-buttons-group"
                                                    onChange={(e) => setCategory(e.target.value)}
                                                    name="category-radio-buttons"
                                                    value={category}
                                                >
                                                  {categories.map((el, i) => (
  // Der `key` Prop ist nun Teil der `FormControlLabel` Komponente.
  <FormControlLabel key={i} value={el} control={<Radio size="small" />} label={<span className="text-sm">{el}</span>} />
))}
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    )}

                                </div>
                             
                                
                                
                                
                                {/* ratings filter */}
                                <div className="flex flex-col border-b px-4">

                                    <div className="flex justify-between cursor-pointer py-1 pb-4 items-center" onClick={() => setRatingsToggle(!ratingsToggle)}>
                                        <p className="font-medium text-xs uppercase">ratings</p>
                                        {ratingsToggle ?
                                            <ExpandLessIcon sx={{ fontSize: "20px" }} /> :
                                            <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                                        }
                                    </div>

                                    {ratingsToggle && (
                                        <div className="flex flex-col pb-1">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="ratings-radio-buttons-group"
                                                    onChange={(e) => setRatings(e.target.value)}
                                                    value={ratings}
                                                    name="ratings-radio-buttons"
                                                >
                                                   {[4, 3, 2, 1].map((rating, i) => (
  // Jeder `FormControlLabel` für Bewertungen erhält einen eindeutigen `key` Prop.
  <FormControlLabel key={i} value={rating} control={<Radio size="small" />} label={<span className="flex items-center text-sm">{rating}<StarIcon sx={{ fontSize: "12px", mr: 0.5 }} /> & über</span>} />
))}
                                                </RadioGroup>
                                          <br />
                                                
                                            </FormControl>
                                            
                                        </div>
                                    )}

                                </div>
                                {/* ratings filter */}

                            </div>

                        </div>
                        {/* <!-- nav tiles --> */}

                    </div>
                    {/* <!-- sidebar column  --> */}

                    {/* <!-- search column --> */}
                    <div className="flex flex-1 flex-col">

{loading ? (
  <Loader />
) : products?.length === 0 ? (
  <div className="flex flex-col items-center justify-center gap-3 bg-white shadow-sm rounded-lg p-6 sm:p-16">
    <img draggable="false" className="w-1/2 max-w-xs object-contain" src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/error-no-search-results_2353c5.png" alt="Search Not Found" />
    <h1 className="text-2xl font-semibold text-gray-900">Leider wurden keine Ergebnisse gefunden!</h1>
    <p className="text-lg text-center text-gray-600">Bitte überprüfen Sie die Rechtschreibung oder versuchen Sie, nach etwas anderem zu suchen</p>
  </div>
) : (
  <div className="flex flex-col gap-4 justify-center items-center w-full bg-white">
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full p-4">
      {products.map((product) => (
        <Product {...product} key={product._id} />
      ))}
    </div>
    {filteredProductsCount > resultPerPage && (
      <Pagination
        count={Number(((filteredProductsCount + 6) / resultPerPage).toFixed())}
        page={currentPage}
        onChange={(e, val) => setCurrentPage(val)}
        className="mt-4"
        color="primary"
      />
    )}
  </div>
)}

</div>

                    {/* <!-- search column --> */}
                </div >
                {/* <!-- row --> */}

            </main >
        </>
    );
};

export default Products;
