const PriceSidebar = ({ cartItems, total, deliveryCharges }) => {
    const itemsPrice = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
//console.log(total);
    // Fallback für deliveryCharges, wenn es nicht definiert ist
    const effectiveDeliveryCharges = deliveryCharges !== undefined ? deliveryCharges : (total >= 50 ? 0 : 6.95);

    // Fallback für total, wenn es nicht definiert ist
    const effectiveTotal = total !== undefined ? total : itemsPrice + effectiveDeliveryCharges;
console.log(total);
    // Berechnet den durch den Coupon gesparten Betrag
    const savedWithCoupon = itemsPrice + effectiveDeliveryCharges - effectiveTotal; 

    return (
        <div className="flex sticky top-16 sm:h-screen flex-col sm:w-4/12 sm:px-1">
            <div className="flex flex-col bg-white rounded-sm shadow">
                <h1 className="px-6 py-3 border-b font-medium text-gray-500">PREISDETAILS</h1>
                <div className="flex flex-col gap-4 p-6 pb-3">
                    <p className="flex justify-between">Preis ({cartItems.length} Artikel) <span>{itemsPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</span></p>
                    {
  savedWithCoupon > 0 && (
    <p className="flex justify-between">
      Eingespart durch Coupon 
      <span className="text-primary-green">
        - {savedWithCoupon.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
      </span>
    </p>
  )
}
                    <p className="flex justify-between">Versandkosten <span className={effectiveDeliveryCharges === 0 ? "text-primary-green" : ""}>{effectiveDeliveryCharges.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</span></p>
                    <div className="border border-dashed"></div>
                    <p className="flex justify-between text-lg font-medium">Gesamtsumme <span>{effectiveTotal.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</span></p>
                </div>
            </div>
        </div>
    );
};

export default PriceSidebar;
