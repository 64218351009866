import { useSelector } from 'react-redux';
import CartItem from './CartItem';
import PriceSidebar from './PriceSidebar';
import Stepper from './Stepper';
import { useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
const OrderConfirm = () => {

    const navigate = useNavigate();
    const { cartItems } = useSelector((state) => state.cart);
    const { user } = useSelector((state) => state.user);

    const [guestEmail, setGuestEmail] = useState(localStorage.getItem('guestEmail') );
    const isGuest = !user?.email;
    const userEmail = user?.email || guestEmail;
    useEffect(() => {
        // Aktualisieren des Zustands, wenn die E-Mail in der Local Storage gefunden wird
        if (isGuest && guestEmail) {
            localStorage.setItem('guestEmail', guestEmail);
            localStorage.setItem('Rolle', 'gast');
        }
    }, [guestEmail, isGuest]);

    // Behandlung der Formularübermittlung für Gastnutzer
    const handleGuestEmailSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem('guestEmail', guestEmail); // Speichern der E-Mail in der Local Storage
        navigate('/process/payment');
    };
    return (
        <>
            <MetaData title="Holzwurm: Order Confirmation" />
            <main className="w-full mt-20 pt-10">
                <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
                    <div className="flex-1">
                        <Stepper activeStep={2}>
                            <div className="w-full bg-white">
                                {cartItems?.map((item, i) => (
                                    <CartItem {...item} inCart={false} key={i} />
                                ))}
                            </div>
                            <div className="flex flex-col sm:flex-row justify-between items-center mt-4 bg-white px-6 py-3 rounded-b-sm gap-4">
    {isGuest ? (
        <form onSubmit={handleGuestEmailSubmit} className="flex flex-col sm:flex-row w-full justify-between items-center gap-80">
            <TextField
                type="email"
                placeholder="Enter your email"
                aria-label="Email Address"
                value={guestEmail}
                
                onChange={(e) => setGuestEmail(e.target.value)}
                required
                className="flex-col w-80 border-2 border-gray-300 rounded-lg p-2 text-sm focus:outline-none focus:border-primary-blue transition duration-150 ease-in-out"
            />
            <button type="submit" className={`${cartItems.length < 1 ? "bg-gray-400 cursor-not-allowed" : "bg-primary-green hover:bg-primary-dark"} px-6 py-2 text-white font-medium rounded-lg shadow-sm hover:shadow-md transition duration-150 ease-in-out`}>
                Fortfahren
            </button>
        </form>
    ) : (
        <p className="text-sm sm:flex-grow">Die E-Mail mit der Bestellbestätigung wird an gesendet <span className="font-medium">{userEmail}</span></p>
    )}
    {!isGuest && <button onClick={() => { navigate('/process/payment') }} className={`${cartItems.length < 1 ? "bg-gray-400 cursor-not-allowed" : "bg-primary-green hover:bg-primary-dark"} px-6 py-2 text-white font-medium rounded-lg shadow-sm hover:shadow-md transition duration-150 ease-in-out`}>Fortfahren</button>}
</div>

                        </Stepper>
                    </div>
                    <PriceSidebar cartItems={cartItems} />
                </div>
            </main>
        </>
    );
};
export default OrderConfirm;